.GroupList {
  & .GroupList__heading {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    margin-bottom: 32px;

    @media (--tablet) {
      margin-bottom: 64px;
    }

    & label {
      flex: 1;
      flex-basis: calc(100% - 46px - 16px);
      min-width: 0;

      @media (--smallPhone) {
        flex-basis: calc(100% - 46px - 16px);
        font-size: 0.5em;
        width: 20px;
      }
    }

    & .GroupList__button.GroupList__button.GroupList__button {
      @mixin primarySolidButton;
      margin-left: auto;

      @media (--mobileOnly) {
        @mixin primaryCircleButton;
      }
    }

    & .GroupList__buttonText {
      @media (--mobileOnly) {
        @mixin visuallyHidden;
      }
    }
  }

  & .GroupList__filters {
    display: flex;
    justify-content: flex-end;
    margin: 40px 0 16px;

    @media (--tablet) {
      margin: 64px 0 32px;
    }
  }

  & .GroupList__list {
    @mixin clearList;

    @media (--tablet) {
      display: flex;
      flex-wrap: wrap;
    }

    & li {
      margin-bottom: 8px;

      @media (--tablet) {
        @mixin lost-column 6/12 2;
        margin-bottom: 40px;
      }

      @media (--desktop) {
        @mixin lost-column 4/12 3;
      }
    }
  }

  & .GroupList__modalInputErrorMessage {
    @mixin p40;
    @mixin errorMessage var(--colorNotificationError), 8px;
    margin-bottom: 24px;

    @media (--tablet) {
      margin-bottom: 40px;
    }
  }

  & .GroupList__listSizeMarkup {
    margin-left: 16px;

    @media (--tablet) {
      margin-left: 32px;
    }
  }

  & .GroupList__loadMoreButton {
    margin: 32px auto 0;
  }

  & .GroupList__input {
    flex: 1;
    max-width: auto;

    @media (--tablet) {
      max-width: 400px;
    }
  }
}
