.CoursePackageListItem {
  background-color: var(--backgroundColor);
  overflow: hidden;
  color: var(--textColor);
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;

  & .CoursePackageListItem__header {
    background-color: var(--secondaryLighten20);
    color: var(--lightTextColor);
    min-height: 120px;
    display: flex;
    align-items: center;
  }

  & .CoursePackageListItem__title {
    @mixin h40;
    @mixin lineClamp 3;
    margin: 8px 24px;

    @media (--desktop) {
      @mixin h30;
      @mixin lineClamp 2;
      margin: 8px 40px;
    }
  }

  & .CoursePackageListItem__courses {
    @mixin clearList;
    font-weight: var(--boldFontWeight);
    flex: 1;
    margin: 40px 24px;

    @media (--desktop) {
      margin: 56px 40px;
    }
  }

  & .CoursePackageListItem__courseInPackage {
    @mixin p20;
    margin-top: 24px;

    @media (--desktop) {
      @mixin h50;
    }
  }

  & .CoursePackageListItem__courseIcon {
    stroke: var(--primaryBase);
  }

  & .CoursePackageListItem__splitLine {
    width: 100%;
    height: 1px;
    background-color: var(--secondaryLighten40);
  }

  & .CoursePackageListItem__actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 24px 24px 32px;

    @media (--desktop) {
      flex-direction: row;
      justify-content: space-between;
      margin: 40px;
    }
  }

  & .CoursePackageListItem__priceContainer {
    display: inline-flex;
    align-items: center;
  }

  & .CoursePackageListItem__price {
    @mixin h20;
    font-weight: var(--boldFontWeight);
    color: var(--colorNotificationSuccess);
  }

  & .CoursePackageListItem__priceDescription {
    @mixin p40;
    color: var(--secondaryLighten20);
    margin-left: 16px;
  }

  & .CoursePackageListItem__readMoreButton {
    width: 100%;
    margin-top: 24px;

    @media (--desktop) {
      width: auto;
      margin: 0;
    }
  }
}
