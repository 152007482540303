.Chapter {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  & .Chapter__title {
    @mixin p20;
    margin-bottom: 16px;

    @media (--tablet) {
      @mixin h30;
      margin-bottom: 24px;
    }
  }

  & .Chapter__content {
    flex: 1;
    margin-bottom: 64px;
  }
}
