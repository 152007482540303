.MemberListOptions {
  width: 100%;

  & .MemberListOptions__loader {
    width: 260px;
    height: 24px;
  }

  & .MemberListOptions__addToGroupButton {
    @media (--mobileOnly) {
      margin-top: 16px;
    }
  }

  & .MemberListOptions__content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;

    & .MemberListOptions__mainCheckboxSection {
      display: flex;
      align-items: center;
    }

    & .MemberListOptions__selectAllCheckbox {
      margin-right: 16px;

      @media (--tablet) {
        margin-right: 32px;
      }

      & .Checkbox__labelContent {
        position: relative;
      }
    }

    & .MemberListOptions__membersActionsPopover {
      & button.MemberListOptions__deleteMembersButton {
        width: auto;
        display: block;
        margin: 0 auto;
      }
    }

    & .MemberListOptions__selectedMembersCount {
      @mixin p30;
      margin-left: 40px;

      @media (--tablet) {
        margin-left: 48px;
      }

      & strong {
        margin-right: 4px;
      }
    }
  }
}

& .MemberListOptions__modal {
  & .MemberListOptions__modal--button {
    margin-top: 16px;
  }

  /* stylelint-disable no-descending-specificity */

  & .MemberListOptions__modal-all-groups-checkbox {
    display: flex;
    margin-bottom: 16px;

    & .Checkbox__labelContent {
      position: relative;
    }
  }

  & .MemberListOptions__modal-header {
    @media (--mobileOnly) {
      font-size: 1.3em;
      align-items: center;
    }
  }

  & .MemberListOptions__modal-paragraph {
    align-self: center;
    margin-left: auto;

    @media (--mobileOnly) {
      align-self: center;
      font-size: 0.9em;
      margin-right: auto;
      margin-left: 0;
    }
  }

  & .MemberListOptions__modal-group-count {
    margin-top: 16px;
  }

  & .MemberListOptions__modal-create-new-button-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    & button {
      padding: 16px;
    }
  }

  & .MemberListOptions__modal-input {
    margin-bottom: 16px;

    @media (--mobileOnly) {
      & .Input__label {
        font-size: 1.2em;
      }
    }
  }

  & .MemberListOptions__modal-header-container {
    display: flex;
    margin-bottom: 16px;

    @media (--mobileOnly) {
      flex-direction: column;
    }
  }

  & .MemberListOptions__modal-create-button {
    align-self: center;
  }

  & .MemberListOptions__modal-create-button-open {
    & svg {
      transform: rotate(0);
    }
  }

  & .MemberListOptions__modal-info-text {
    white-space: pre-wrap;
    margin-top: 16px;
  }

  & .MemberListOptions__modal-input-button-container {
    display: flex;
    gap: 16px;
    margin-top: 36px;

    @media (--mobileOnly) {
      flex-direction: column;
    }

    & label {
      width: 100%;
    }

    & button {
      align-self: flex-end;
      height: 44px;
      margin-bottom: 2px;
    }
  }

  & .MemberListOptions__modal-input-button-wrapper {
    display: flex;
    justify-content: center;
  }

  & .MemberListOptions__modal--group-list {
    list-style: none;
    max-height: 270px;
    min-height: 270px;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    transition: 0.5s ease-in-out;
    padding: 6px;
    border-radius: 6px;
    margin: 0;

    & p {
      padding: 16px;
    }
  }
}

& .MemberListOptions_connectionModal {
  & h1 {
    line-height: 1.2em;
    white-space: pre-line;
    margin-bottom: 32px;
  }

  & p {
    white-space: pre-line;
  }

  & .MemberListOptions_connectionModal-buttons {
    display: flex;
    justify-content: left;
    gap: 16px;
    margin-top: 36px;

    @media (--mobileOnly) {
      flex-direction: column;
    }
  }
}

& .Member__checkbox {
  margin: auto 16px auto 0;

  @media (--tablet) {
    margin-right: 32px;
  }
}

.DisabledButton {
  opacity: 0.2;

  &:hover {
    cursor: not-allowed;
  }
}

.NoResultWrapper {
  display: flex;
  justify-content: center;
}

.AddToMoreThanOneGroupButton {
  align-self: center;
  min-height: 40px;
}

.CreateNewGroupButton {
  min-height: 40px;
}

.SuccessView {
  display: flex;
  justify-content: center;
  margin-top: 36px;

  @media (--mobileOnly) {
    white-space: pre-wrap;
    max-width: 300px;
    flex-wrap: wrap;
  }
}

.AdminViewDeleteAddButtons {
  display: flex;
  gap: 16px;
  flex-direction: column;

  @media (--mobileOnly) {
    flex-direction: column;
  }

  @media (--tablet) {
    flex-direction: column;
  }
}

.AdminViewDeleteAddButtonsOutsidePopover {
  display: flex;
  gap: 16px;
}

.MemberListOptions__addToGroupButton {
  margin: 6px 0;
}

/* stylelint-enable no-descending-specificity */
