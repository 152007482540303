.TeasersSection {
  margin-bottom: 80px;

  @media (--tablet) {
    margin-bottom: 120px;
  }

  & .TeasersSection__header {
    text-align: center;
  }

  & .TeasersSection__title {
    @mixin h30;
    font-weight: var(--boldFontWeight);

    @media (--tablet) {
      @mixin h20;
    }
  }

  & .TeasersSection__cardsWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: var(--gutter);
    row-gap: 16px;
    margin-top: 40px;

    @media (--desktop) {
      grid-template-columns: 4fr 4fr 4fr;
      grid-template-rows: 1fr;
      margin-top: 64px;
    }
  }
}
