.ExamTaskAccordion {
  background-color: var(--darkBackgroundColor);
  color: var(--secondaryLighten40);
  transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;
  border: 1px solid var(--secondaryLighten30);
  border-radius: 8px;

  & .ExamTaskAccordion__heading {
    @mixin actionOverlay;
    display: flex;
    align-items: center;
    padding: 16px 24px;

    @media (--tablet) {
      padding: 24px 32px;
    }
  }

  & .ExamTaskAccordion__marker {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 16px;

    & .CheckedCircle__filledCircle {
      transition: fill 0.3s ease-out;
    }

    & .CheckedCircle__mark,
    & .CheckedCircle__circle {
      transition: opacity 0.3s ease-out;
    }

    &:not(.ExamTaskAccordion__marker--checked) {
      & .CheckedCircle__filledCircle {
        fill: var(--secondaryLighten30);
      }

      & .CheckedCircle__mark,
      & .CheckedCircle__circle {
        opacity: 0;
      }
    }
  }

  & .ExamTaskAccordion__title {
    @mixin p20;

    @media (--tablet) {
      @mixin h50;
    }
  }

  & .ExamTaskAccordion__arrow {
    color: var(--secondaryLighten30);
    flex-shrink: 0;
    margin-left: auto;
  }

  & .ExamTaskAccordion__content {
    padding: 24px 24px 64px;

    @media (--tablet) {
      padding: 0 32px 24px;
    }
  }

  & .ExamTaskAccordion__task {
    &.OptionStatus--correct,
    &.OptionStatus--wrong {
      &::before {
        background-color: var(--white); /* TO DO: GET RID OF THE EXCEPTION */
      }
    }
  }

  &.ExamTaskAccordion--active {
    background-color: var(--white);
    color: var(--textColor);
    border: 1px solid transparent;

    & .ExamTaskAccordion__arrow {
      color: var(--textColor);
    }
  }
}
