.MemberProfile {
  & .MemberProfile__removeUserButton.MemberProfile__removeUserButton {
    @mixin primaryLink;
    width: auto;
    display: block;
    font-family: var(--baseFontFamily);
    margin: 0 auto;
  }

  & .MemberProfile__resendInvitationButton {
    margin-top: 8px;
  }
}
