.Avatar {
  @mixin aspectRatio 1/1;
  @mixin p50;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--textColor);
  background-color: var(--secondaryLighten60);
  position: relative;
  border-radius: 50%;

  &.Avatar--small {
    @mixin smallAvatar;
  }

  &.Avatar--medium {
    @mixin mediumAvatar;
  }

  &.Avatar--large {
    @mixin largeAvatar;
  }

  &.Avatar--xLarge {
    @mixin xLargeAvatar;
  }

  &.Avatar--xxLarge {
    @mixin xxLargeAvatar;
  }

  &.Avatar--xxxLarge {
    @mixin xxxLargeAvatar;
  }

  & .Avatar__rightsIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--textColor);
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    border-radius: 50%;

    &.Avatar__rightsIndicator--manager {
      background-color: var(--colorActionWarning);
    }

    &.Avatar__rightsIndicator--admin {
      background-color: var(--primaryBase);
    }
  }
}
