.Button {
  &.Button--solid {
    &.Button--primary {
      @mixin button var(--darkBrandColor), var(--brandColor), var(--white);
    }

    &.Button--secondary {
      @mixin button var(--secondaryBase), var(--secondaryLighten30), var(--white);
    }

    &.Button--tertiary {
      @mixin button var(--white), var(--secondaryLighten60), var(--secondaryBase);
    }
  }

  &.Button--outline {
    &.Button--primary {
      @mixin outlineButton var(--darkBrandColor), var(--primaryLighten60);
    }

    &.Button--secondary {
      @mixin secondaryOutlineButton;
    }

    &.Button--tertiary {
      @mixin outlineButton var(--white), var(--secondaryLighten20);
    }
  }

  &.Button--circle {
    &.Button--primary {
      @mixin circleButton var(--darkBrandColor), var(--brandColor), var(--white);
    }

    &.Button--secondary {
      @mixin circleButton var(--secondaryBase), var(--secondaryLighten30), var(--white);
    }

    &.Button--tertiary {
      @mixin circleButton var(--white), var(--secondaryLighten60), var(--secondaryBase);
    }
  }

  &.Button--clear {
    @mixin resetButtonStyles;
  }

  &.Button--xSmall {
    @mixin xSmallButton;
  }

  &.Button--small {
    @mixin smallButton;
  }

  &.Button--medium {
    @mixin mediumButton;
  }

  &.Button--large {
    @mixin largeButton;
  }

  &.Button--xLarge {
    @mixin xLargeButton;
  }
}
