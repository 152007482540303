.CourseHero {
  background-color: var(--darkBackgroundColor);
  color: var(--lightTextColor);
  padding-top: 40px;

  @media (--tablet) {
    padding-top: 95px;
  }

  & .CourseHero__inner {
    @mixin wrapper;
    display: flex;
    flex-direction: column;

    @media (--tablet) {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  & .CourseHero__info {
    margin-bottom: 54px;

    @media (--tablet) {
      @mixin lost-column 6/12;
      margin-bottom: 97px;
    }

    & .CourseHero__title {
      @mixin h10;
      @mixin lineUnder;
      display: inline-block;
      margin-bottom: 52px;

      @media (--tablet) {
        margin-bottom: 58px;
      }
    }
  }

  & .CourseHero__imageWrapper {
    @mixin aspectRatio 207/354;
    margin-bottom: -65px;

    @media (--tablet) {
      @mixin lost-column 6/12;
      @mixin aspectRatio 456/699;
      margin-bottom: -80px;
    }

    @media (--aboveWrapper) {
      @mixin shiftHorizontallyBy 66px;
    }

    & .CourseHero__image {
      @mixin absoluteParentSize;
      object-fit: cover;
      border-bottom-left-radius: 40px;
    }
  }
}
