@define-mixin breakWithHyphen {
  overflow-wrap: break-word;
  hyphens: auto;
  word-break: break-word;
}

@define-mixin breakWithoutHyphen {
  overflow-wrap: break-word;
  word-break: break-word;
}

@define-mixin breakLink {
  word-break: break-all;
}

@define-mixin lineClamp $line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  padding: 0;
}
