.CookiesConsent {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
  background-color: var(--secondaryLighten60);
  filter: var(--effectShadow30);
  border-top-right-radius: 48px;

  @media (--tablet) {
    border-top-right-radius: 64px;
  }

  & .CookiesConsent__inner {
    @mixin wrapper;
    padding-top: 24px;
    padding-bottom: 40px;

    @media (--tablet) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 48px;
      padding-bottom: 24px;
    }

    & .CookiesConsent__message {
      @media (--tablet) {
        flex: 1;
      }

      & a {
        text-decoration: underline;
        font-weight: 700;
      }
    }

    /* stylelint-disable max-nesting-depth */

    & .CookiesConsent__buttons {
      display: flex;
      align-items: center;
      gap: 32px;

      @media (--mobileOnly) {
        justify-content: space-evenly;
        margin-top: 24px;

        & button {
          width: 150px;
          font-size: 0.9em;
          padding: 8px;
        }
      }
      /* stylelint-enable max-nesting-depth */
    }
  }
}

.DisabledInput {
  & label {
    cursor: not-allowed !important;
    opacity: 0.4;
  }
}

.ConsentModal {
  & p {
    margin: 16px 16px 16px 0;
  }

  & input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  & label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 30px;
    background: grey;
    display: block;
    position: relative;
    border-radius: 100px;

    &::after {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      width: 20px;
      height: 20px;
      background: #ffffff;
      transition: 0.3s;
      border-radius: 90px;
    }
  }

  & input:checked + label {
    background: #bada55;

    &::after {
      left: calc(100% - 5px);
      transform: translateX(-100%);
    }
  }

  & .ConsentModal__header::after {
    content: "";
    display: block;
    width: 20%;
    height: 2px;
    background: var(--primaryBlack);
    margin: 16px 16px 16px 0;
  }

  & .ConsentModal__link {
    text-decoration: underline;
    padding: 8px 0 8px 0;
  }

  & .ConsentModal__necessary-cookie-button-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 16px 16px 16px 0;

    & h3 {
      width: calc(100% - 80px);
    }
  }

  & .ConsentModal__necessary-cookie-text::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: var(--lightGrey);
    margin-top: 32px;
  }

  & .ConsentModal__title-and-toggle-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }
}

.AcceptButton__wrapper {
  display: flex;
  justify-content: flex-end;
}
