.MatchTaskRow {
  & .MatchTaskRow__button-wrapper {
    display: flex;
    align-items: center;
    padding: 12px;

    @media (--mobileOnly) {
      width: 2px;
      padding: 0;
      margin-left: 10px;
    }
  }

  & .MatchTaskRow__status {
    height: 68px;
    width: 120px;
    position: relative;
    left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    &::before {
      content: "";
      position: absolute;
      left: -12px;
      top: 19px;
      background-color: var(--secondaryLighten60);
      width: 20px;
      height: 20px;
      z-index: 1;
      border: 1px solid var(--primaryBlack);
      border-radius: 50%;
    }
  }

  & .MatchTaskRow__status-correct {
    border: 1px solid var(--colorActionSuccess);

    & .Radio__label {
      color: var(--colorActionSuccess);
    }
  }

  & .MatchTaskRow__status-hidden {
    visibility: hidden;
    height: 68px;
  }

  & .MatchTaskRow__status-incorrect {
    border: 1px solid var(--colorActionError);
  }

  & .MatchTaskRow__status-should-have-chosen {
    background-color: var(--colorActionSuccessLighten60);
    width: 120px;
    border: 1px solid var(--colorActionSuccessLighten60);
  }

  & p {
    font-size: 0.7em;
    padding: 16px;
  }

  & td {
    font-size: 1.2em;
    width: 200px;
    padding: 10px;
    border-right: 2px solid var(--lightGrey);
    border-bottom: 2px solid var(--lightGrey);

    @media (--mobileOnly) {
      padding: 0;
    }

    &:last-child {
      border-right: none;
    }

    &:first-child {
      font-weight: bold;
    }

    &:hover {
      &:not(:first-child) {
        cursor: pointer;
      }
    }

    & p {
      font-size: 0.8em;
    }

    &.correct-answer {
      background-color: var(--colorActionSuccess);
      border-radius: 5px;
    }

    &.incorrect-answer {
      background-color: var(--colorActionError);
      border-radius: 5px;
    }
  }

  /* Custom radio button */

  & input {
    display: none;

    & + label {
      position: relative;
      width: 20px;
      height: 20px;
      display: inline-block;
      z-index: 2;

      &::before {
        content: "";
        position: absolute;
        left: -1px;
        top: -1px;
        width: 20px;
        height: 20px;
        border: 1px solid var(--primaryBlack);
        border-radius: 50%;
      }

      &::after {
        content: "";
        position: absolute;
        left: 2px;
        top: 2px;
        width: 14px;
        height: 14px;
        background-color: var(--secondaryBase);
        transform: scale(0);
        transition: transform 0.3s ease;
        border-radius: 50%;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &:checked + label::after {
      transform: scale(1);
    }
  }
}
