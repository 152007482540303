.RettsdataPortableText {
  @mixin p20;

  @media (--tablet) {
    @mixin p10;
  }

  & h1 {
    @mixin h30;
    @mixin lineUnder 16px, var(--grayscaleBlackLighten30), 96px, 1px;
    margin: 0 0 24px;

    @media (--tablet) {
      @mixin h20;
      @mixin lineUnder 24px, currentColor, 128px, 2px;
      margin-bottom: 48px;
    }
  }

  & h2 {
    @mixin h40;
    @mixin lineUnder 16px, var(--grayscaleBlackLighten30), 96px, 1px;
    margin: 0 0 16px;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 24px, currentColor, 128px, 2px;
      margin-bottom: 32px;
    }
  }

  & h3,
  & h4,
  & h5,
  & h6 {
    @mixin h50;
    @mixin lineUnder 16px, var(--grayscaleBlackLighten30), 96px, 1px;
    margin: 0 0 16px;

    @media (--tablet) {
      @mixin h40;
      @mixin lineUnder 24px, currentColor, 128px, 2px;
    }
  }

  & p {
    margin: 0 0 1em;
  }

  & .id-karnov-note {
    display: none;
  }
}
