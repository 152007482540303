.TextHeader {
  @mixin textHeaderMargin;
  display: flex;
  align-items: center;

  & .TextHeader__title {
    @mixin h30;
    display: flex;
    align-items: center;
    flex: 1;

    @media (--tablet) {
      @mixin h20;
    }

    &::after {
      content: "";
      flex: 1;
      height: 1px;
      background-color: var(--primaryGrey);
      margin-left: 16px;
    }
  }

  & .TextHeader__content {
    margin-left: 16px;
  }

  &.TextHeader--secondary {
    & .TextHeader__title {
      @media (--tablet) {
        @mixin h30;
      }
    }
  }

  &.TextHeader--tertiary {
    & .TextHeader__title {
      @mixin p40;
      font-weight: var(--normalFontWeight);
    }
  }
}
