.MyProfile {
  & .MyProfile__editProfileButton.MyProfile__editProfileButton {
    @mixin primaryLink;
    @mixin p30;
  }

  & .MoreActionsPopover__popoverContent {
    text-align: center;
  }
}
