.Slider {
  @mixin clearList;

  @media (--IE11) {
    pointer-events: auto;
    overflow: auto !important;
  }

  & .keen-slider__slide {
    @mixin slideWidth 3, 12px;

    @media (--tablet) {
      @mixin slideWidth 5, 24px;
    }

    @media (--desktop) {
      @mixin slideWidth 7, 32px;
    }
  }
}
