.Banner {
  background-color: var(--secondaryLighten10);
  max-width: 1280px;
  width: 100%;
  border-radius: 16px;
  margin-right: auto;
  margin-bottom: 12px;
  margin-left: auto;

  & .Banner__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 16px 32px;

    & h2,
    p {
      padding: 0;
      margin: 0;
    }

    & button {
      color: var(--white);
      border: none;
      border-radius: 6px;
      margin-left: auto;

      @media (--mobileOnly) {
        position: relative;
        font-size: 1.5em;
        padding: 0;
      }

      &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    & a {
      background-color: var(--primaryDarken30);
      width: fit-content;
      padding: 6px 12px;
      border-radius: 12px;
      margin-top: 12px;
    }
  }
}
