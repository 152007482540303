.ImageWithText {
  padding: 48px 0 64px;

  @media (--desktop) {
    display: flex;
    align-items: flex-start;
    padding: 80px 0;
  }

  & .ImageWithText__title {
    @mixin h30;
    @mixin lineUnder 16px;

    @media (--tablet) {
      @mixin h20;
      @mixin lineUnder 24px;
    }
  }

  & .ImageWithText__description {
    @mixin p10;
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }
  }

  & .ImageWithText__text {
    @media (--desktop) {
      @mixin lost-column 5/12;
      margin-bottom: 0;
    }

    @media (--desktopHD) {
      @mixin lost-offset 1/12;
      @mixin lost-column 4/12;
    }
  }

  & .ImageWithText__imageWrapper {
    @mixin aspectRatio 2/3;
    margin-top: 48px;

    @media (--tablet) {
      @mixin lost-offset 1/12;
      @mixin lost-column 10/12;
      margin-top: 0;
    }

    @media (--desktop) {
      @mixin lost-offset 1/12;
      @mixin lost-column 6/12;
    }

    & .ImageWithText__image {
      object-fit: contain;
    }
  }
}
