.CourseInformation {
  overflow-x: hidden;

  & .CourseInformation__content {
    @mixin wrapper;
    padding-top: 100px;
    padding-bottom: 80px;

    @media (--tablet) {
      padding-top: 140px;
      padding-bottom: 227px;
    }
  }

  & .CourseInformation__introductionWrapper {
    margin-bottom: 64px;
  }

  & .CourseInformation__introduction {
    @media (--tablet) {
      @mixin lost-column 8/12;
    }

    & .CourseInformation__introductionCollapse {
      & .TextCollapse__collapse {
        min-height: calc(4 * var(--baseLineHeight));
      }
    }

    & .CourseInformation__introductionButton {
      @mixin primaryLink;
      margin-top: 16px;
    }
  }

  & .CourseInformation__integrations {
    & div {
      display: flex;
      gap: 3px;
    }

    & svg {
      cursor: pointer;
    }

    & .CourseInformation__dossierStatus--text {
      & p {
        font-size: 1.3rem;
        display: flex;
        align-items: center;
      }

      & .CourseInformation__dossierStatus--completed {
        color: var(--colorActionSuccess);
      }

      & .CourseInformation__dossierStatus--notCompleted {
        color: var(--colorActionError);
      }
    }

    & .CourseInformation__dossierStatus--submit {
      display: flex;
      flex-direction: column;
      margin-top: 12px;

      & button {
        width: fit-content;
        margin-top: 12px;
      }
    }

    & .CourseInformation__dossier--loader {
      position: relative;
      width: 130px;
      height: 24px;
    }
  }
}
