@define-mixin choice $borderColor, $fillColor {
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }

  &:not(:checked) + label,
  &:checked + label {
    @mixin p40;
    position: relative;
    cursor: pointer;
    padding-left: var(--choicePaddingLeft);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: var(--choiceElementSize);
      height: var(--choiceElementSize);
      transition: border-color 0.3s;
      border: 1px solid $borderColor;
    }

    &::after {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      background-color: $fillColor;
      transition: opacity 0.2s, transform 0.2s;
    }
  }

  &:not(:checked) + label::after {
    opacity: 0;
    transform: translate(-1px, 2px);
  }

  &:checked + label::after {
    opacity: 1;
    transform: translate(1px, -2px);
  }

  &:disabled:not(:checked) + label,
  &:disabled:checked + label {
    cursor: default;
    color: var(--secondaryLighten30);

    &::before {
      border-color: var(--secondaryLighten30);
    }

    &::after {
      background-color: var(--secondaryLighten30);
    }
  }

  &.focus-visible:focus + label {
    @mixin offsetFocusInner;
  }
}

@define-mixin choiceWithHiddenLabel {
  @mixin choice;

  &:not(:checked) + label,
  &:checked + label {
    padding-left: var(--choiceElementSize);
  }
}

@define-mixin shiftPseudoElementsVertically $value {
  &::before,
  &::after {
    margin-top: $value;
  }
}

@define-mixin overwriteOptionStatusBackground $color {
  & .OptionStatus--correct,
  & .OptionStatus--wrong {
    &::before {
      background-color: $color;
    }
  }
}
