@define-mixin heroImageOverlay {
  @mixin absoluteParentSize;
  background-color: var(--darkBackgroundColor);
  opacity: 0.6;

  & img {
    @mixin absoluteParentSize;
    object-fit: cover;
    opacity: 0.6;
  }
}
