.ManageMembers {
  & .ManageMembers__hero .Hero__descriptionInner {
    padding-bottom: 32px;

    @media (--tablet) {
      padding-bottom: 48px;
    }
  }

  & .ManageMembers__scrollTargets {
    position: relative;
    bottom: 80px;
  }
}
