.StartedCourseList {
  margin-bottom: 96px;

  &.StartedCourseList--empty {
    @mixin textHeaderMargin;
  }

  & .StartedCourseList__list {
    & li {
      margin-bottom: 32px;

      @media (--tablet) {
        margin-bottom: 48px;
      }
    }
  }
}
