.CustomTable {
  font-size: 0.8em;
  border-radius: 6px;
  margin-top: 32px;

  @media (--mobileOnly) {
    font-size: 0.6em;
  }

  & th {
    padding: 8px;
  }

  & .CustomTable__row {
    & td {
      outline: 1px solid var(--primaryBlack);
      padding: 8px;

      @media (--mobileOnly) {
        padding: 0 6px;
      }
    }
  }
}
