.AdminPanelList {
  & .AdminPanelList__actionItemButton.AdminPanelList__actionItemButton {
    @mixin adminListItem;
    @mixin p40;
    justify-content: flex-start;
    font-weight: var(--boldFontWeight);
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease-out;
    margin-bottom: 24px;

    @media (--tablet) {
      @mixin p10;
    }

    &:hover {
      background-color: var(--secondaryLighten20);
    }

    & .AdminPanelList__actionItemIcon {
      margin-right: 18px;
    }
  }
}
