.Fact {
  display: flex;
  align-items: center;
  gap: 16px;

  & .Fact__icon {
    stroke: var(--primaryBase);
    flex-shrink: 0;
    align-self: flex-start;
  }

  & .Fact__content {
    @mixin p10;
  }
}
