.RouteTabs {
  & .RouteTabs__header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondaryLighten30);

    @media (--mobileOnly) {
      flex-direction: column;
    }
  }

  & .RouteTabs__headers {
    display: flex;

    & li {
      position: relative;
    }

    & .RouteTabs__link {
      @mixin p20;
      display: block;
      color: var(--secondaryLighten50);
      padding: 16px 32px;

      @media (--tablet) {
        padding: 16px 24px;
      }

      &:hover {
        color: var(--secondaryLighten60);
      }

      &.RouteTabs__link--active {
        @mixin activeTab var(--primaryBase);
        color: var(--lightTextColor);
      }
    }
  }

  & .RouteTabs__additionalHeaderContent {
    margin-left: 16px;
  }

  & .RouteTabs__content {
    margin-top: 32px;
  }
}
