.NoMembershipPage {
  @mixin darkPageBase;
  background-color: var(--secondaryBase);
  color: var(--white);

  & .NoMembershipPage__contentWrapper {
    @mixin wrapper;
    padding-top: 96px;
  }

  & .NoMembershipPage__infoBox {
    display: grid;
    grid-template-columns: 1fr;

    @media (--desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }

  & .NoMembershipPage__title {
    @mixin h10;
    @mixin lineUnder 24px, var(--white);
  }

  & .NoMembershipPage__descriptionHeader {
    @mixin h40;
    margin-top: 48px;
  }

  & .NoMembershipPage__description {
    @mixin h40;
    font-weight: var(--normalFontWeight);
    margin-top: 24px;

    & .NoMembershipPage__singleUserMessage {
      margin-bottom: 16px;
    }
  }

  & .NoMembershipPage__companyUserMessage {
    margin-top: 56px;
  }

  & .NoMembershipPage__email {
    margin: 0 8px;
  }

  & .NoMembershipPage__link {
    max-width: fit-content;
    margin-top: 32px;
  }

  & .NoMembershipPage__imageWrapper {
    display: none;

    @media (--desktop) {
      @mixin aspectRatio 9/16;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      align-self: center;
    }

    & .NoMembershipPage__image {
      object-fit: contain;
    }
  }

  & .NoMembershipPage__pendingInvitationsWrapper {
    margin-top: 96px;
  }

  & .NoMembershipPage__pendingInvitationsList {
    @mixin clearList;
    margin-top: 32px;

    & li:not(:first-of-type) {
      margin-top: 16px;
    }
  }

  & .NoMembershipPage__pendingInvitationsEmptyList {
    @mixin p20;
    display: block;
    color: var(--secondaryLighten40);
    margin-top: 16px;
  }
}
