.MultiSelectDropdownItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-bottom-color 0.1s;
  padding: 8px 0;
  border-bottom: 1px solid var(--secondaryLighten50);

  & .MultiSelectDropdownItem__displayName {
    @mixin p30;
  }

  &.MultiSelectDropdownItem--highlighted {
    border-bottom-color: var(--secondaryLighten40);
  }
}
