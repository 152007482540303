.CompletedCourseModal {
  @mixin fixedFullSize;
  z-index: 200;

  & .CompletedCourseModal__wrapper {
    @mixin wrapper;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  & .CompletedCourseModal__inner {
    position: relative;
    width: 100%;
    background-color: var(--backgroundColor);
    color: var(--textColor);
    z-index: 1;
    border-radius: 8px;

    @media (--tablet) {
      @mixin lost-column 8/12;
      min-width: 500px;
    }
  }

  & .CompletedCourseModal__imageContainer {
    @mixin aspectRatio 1/1;
    width: 50%;
    max-width: 210px;
    margin: 0 auto 24px;
  }

  & .CompletedCourseModal__content {
    text-align: center;
    padding: 32px 48px 64px;

    @media (--tablet) {
      @mixin lost-offset 1/8;
      @mixin lost-column 6/8;
      padding: 64px 0 96px;
    }

    @media (--desktop) {
      @mixin lost-offset 2/8;
      @mixin lost-column 4/8;
    }
  }

  & .CompletedCourseModal__title {
    @mixin h30;
    margin-bottom: 8px;

    @media (--tablet) {
      @mixin h10;
    }
  }

  & .CompletedCourseModal__description {
    @mixin p10;
    margin-bottom: 32px;

    @media (--tablet) {
      margin-bottom: 48px;
    }
  }

  & .CompletedCourseModal__links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px 24px;

    @media (--desktop) {
      flex-direction: row;
    }
  }

  & .CompletedCourseModal__diplomaLink {
    flex: 1;
    min-width: 185px;
  }

  & .CompletedCourseModal__dashboardLink {
    flex: 1;
    min-width: 150px;
  }

  & .CompletedCourseModal__background {
    @mixin fixedFullSize;
    background: color(var(--secondaryBase) a(80%));
  }
}
