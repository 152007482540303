.TileThumbnail {
  @mixin actionOverlay;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--textColor);
  background-color: var(--white);
  overflow: hidden;
  filter: var(--effectShadow20);
  border-radius: 16px;

  & .TileThumbnail__imageContainer {
    @mixin aspectRatio 9/16;
    width: 100%;
    border-radius: 16px 16px 0 0;

    & .TileThumbnail__image {
      object-fit: cover;
    }
  }

  & .Overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.2);

    &:hover {
      cursor: not-allowed;
    }
  }

  & .Lock {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    z-index: 20;
    margin: 22px;
  }

  & .CourseIsLockedMessage {
    color: var(--colorActionError);
    margin-top: 24px;
  }

  & .TileThumbnail__infoContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 16px 24px 32px;

    @media (--tablet) {
      padding: 24px 32px 40px;
    }
  }

  & .TileThumbnail__expiredText {
    color: var(--colorActionError);
    margin-bottom: 12px;
    margin-left: 32px;
  }

  & .TileThumbnail__titleWrapper {
    @mixin lineUnder 8px, var(--secondaryLighten40), 80%;
    align-self: flex-start;
    margin-bottom: 16px;

    @media (--desktop) {
      margin-bottom: 24px;
    }
  }

  & .TileThumbnail__title {
    @mixin h50;
    @mixin lineClamp 2;

    @media (--desktopHD) {
      @mixin h40;
    }
  }

  & .TileThumbnail__descriptionWrapper {
    flex: 1;
    margin-bottom: 16px;
  }

  & .TileThumbnail__description {
    @mixin p40;
    @mixin lineClamp 3;

    @media (--tablet) {
      @mixin p20;
    }
  }

  & .TileThumbnail__detailedInfo {
    @mixin p50;
    color: var(--secondaryLighten30);
    display: flex;
    margin-top: auto;

    @media (--tablet) {
      @mixin p40;
    }
  }

  & .TileThumbnail__infoItem {
    position: relative;
    padding-right: 10px;
    margin-right: 10px;

    &:not(:last-of-type)::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: var(--secondaryLighten50);
    }

    &:last-of-type {
      padding-right: 0;
      margin-right: 0;
    }
  }

  & .TileThumbnail__bottomContent {
    z-index: 11;
    margin-top: 16px;
  }

  & .TileThumbnail__bottomContent-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  & .TileThumbnail__actionOverlay {
    @mixin insetFocus;
    border-radius: inherit;
  }
}
