.UserEmailPicker {
  & .UserEmailPicker__submitButton {
    margin-left: auto;
  }

  & .UserEmailPicker__betaText {
    font-size: 1.25em;
    margin-bottom: 10px;

    @media (--mobileOnly) {
      font-size: 1em;
    }
  }
}
