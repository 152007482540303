.CollapseGroup {
  & .CollapseGroup__title {
    @mixin h40;

    @media (--desktop) {
      @mixin h30;
    }
  }

  & .CollapseGroup__list {
    @mixin clearList;
    margin-top: 24px;

    @media (--desktop) {
      margin-top: 40px;
    }
  }
}
