.PackagePage {
  & .PackagePage__header {
    @mixin darkPageBase;

    & .PackagePage__title {
      @mixin wrapper;
      @mixin h30;
      font-weight: var(--boldFontWeight);
      padding-top: 40px;
      padding-bottom: 48px;

      @media (--desktop) {
        @mixin h10;
        padding-top: 96px;
        padding-bottom: 96px;
      }
    }
  }

  & .PackagePage__content {
    @mixin wrapper;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "infoTable"
      "thumbnailList"
      "description";
    padding-bottom: 180px;

    @media (--desktop) {
      grid-template-columns: repeat(3, 4fr);
      grid-template-areas:
        "description description infoTable"
        "thumbnailList thumbnailList thumbnailList";
      column-gap: var(--gutter);
      padding-bottom: 128px;
    }
  }

  & .PackagePage__productDataWrapper {
    grid-area: infoTable;
    margin-top: -88px;

    @media (--desktop) {
      margin-top: -144px;
    }
  }

  & .PackagePage__description {
    @mixin p10;
    grid-area: description;
    white-space: pre-line;
    margin-top: 64px;

    @media (--desktop) {
      margin-top: 96px;
    }
  }

  & .PackagePage__courseThumbnails {
    grid-area: thumbnailList;
    margin-top: 80px;
  }

  & .PackagePage__courseThumbnailsTitle {
    @mixin lineAfter;
    @mixin h40;

    @media (--desktop) {
      @mixin h30;
    }
  }

  & .PackagePage__courseThumbnailsList {
    @mixin tileThumbnailList;
    margin-top: 32px;

    @media (--desktop) {
      margin-top: 40px;
    }
  }

  & .PackagePage__courseThumbnailsPrice {
    @mixin p30;
    font-weight: var(--boldFontWeight);
  }

  & .PackagePage__courseThumbnailBottomContent {
    @mixin unclickableElement;
  }
}
