.CoursePackageList {
  @mixin darkPageBase;

  & .CoursePackageList__wrapper {
    @mixin wrapper;
  }

  & .CoursePackageList__header {
    margin: 40px 0;

    @media (--desktop) {
      margin: 96px 0 64px;
    }
  }

  & .CoursePackageList__title {
    @mixin h30;
    font-weight: var(--boldFontWeight);

    @media (--desktop) {
      @mixin h10;
    }
  }

  & .CoursePackageList__bundles {
    @mixin clearList;
    display: grid;
    grid-template-columns: 4fr;
    row-gap: 32px;
    padding-top: 48px;

    @media (--desktop) {
      grid-template-columns: 6fr 6fr;
      column-gap: var(--gutter);
      row-gap: var(--gutter);
      padding-top: 40px;
    }
  }
}
