.Error {
  & .Error__content {
    @mixin wrapper;
    padding-top: 100px;
    padding-bottom: 80px;

    @media (--tablet) {
      padding-top: 140px;
      padding-bottom: 227px;
    }

    & .Error__info {
      @media (--tablet) {
        @mixin lost-column 8/12;
      }
    }

    & .Error__title {
      @mixin h10;
    }

    & .Error__description {
      @mixin h20;
    }

    & .Error_logoutButton {
      max-width: 200px;
      margin-top: 32px;
    }

    & .Error__dashboardButton {
      margin-top: 32px;
    }
  }
}
