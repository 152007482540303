.ChoiceTask {
  & .ChoiceTask__question {
    margin-bottom: 32px;

    & .PortableTextContent {
      @mixin p20;
    }
  }

  & .ChoiceTask__optionsList {
    & li {
      margin-bottom: 32px;

      & > * {
        display: inline-block;
      }
    }

    & label {
      max-width: 100%;

      & > div {
        max-width: 100%;
      }
    }

    & .PortableTextContent__imageWrapper {
      @mixin calculate-columns 7, 6;
      max-width: 100%;
    }
  }
}
