@define-mixin focus {
  &.focus-visible:focus {
    outline: none;
    box-shadow: 0 0 0 4px var(--colorNotificationFocus);
  }
}

@define-mixin insetFocus {
  &.focus-visible:focus {
    box-shadow: inset 0 0 0 4px var(--colorNotificationFocus);
  }
}

@define-mixin offsetFocus $offset: 4px {
  &.focus-visible:focus {
    @mixin offsetFocusInner $offset;
  }
}

@define-mixin offsetFocusInner $offset: 4px {
  outline: 4px solid var(--colorNotificationFocus);
  outline-offset: $offset;
  box-shadow: none;
}

@define-mixin resetFocus {
  &.focus-visible:focus {
    outline: none;
    box-shadow: none;
  }
}
