.UserData {
  @mixin adminListItemBaseData;

  & .UserData__info {
    @mixin adminListItemBaseDataInfo;
  }

  & .UserData__info-without-border {
    & a {
      border-bottom: none;

      @media (--mobileOnly) {
        max-width: 40vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  & .UserData__name {
    @mixin adminListItemBaseDataName;
  }

  & .UserData__email {
    @mixin adminListMemberItemBaseDataEmail;

    @media (--mobileOnly) {
      max-width: 40vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
