.ComboBox {
  & .ComboBox__fieldWrapper {
    position: relative;
  }

  & .ComboBox__inputContainer {
    display: flex;
    align-items: center;
  }

  & .ComboBox__inputIcon {
    color: var(--primaryBase);
    flex-shrink: 0;
    margin-right: 8px;
  }

  & .ComboBox__input {
    @mixin resetInputStyles;
    @mixin p20;
    flex: 1;
  }
}
