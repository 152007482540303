.CoursesOverviewPage {
  @mixin darkPageBase;

  & .CoursesOverviewPage__wrapper {
    @mixin wrapper;
  }

  & .CoursesOverviewPage__header {
    display: grid;
    align-items: center;
    column-gap: var(--gutter);
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "filters";
    margin: 40px 0;

    @media (--desktop) {
      grid-template-columns: 4fr 8fr;
      grid-template-areas: "title filters";
      margin: 96px 0 64px;
    }

    & .CoursesOverviewPage__filters {
      grid-area: filters;
      display: flex;
      flex-direction: column-reverse;
      gap: var(--gutter);
      margin-top: 48px;

      @media (--desktop) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 0;
      }
    }
  }

  & .CoursesOverviewPage__checkbox {
    white-space: nowrap;
  }

  & .CoursesOverviewPage__dropdown {
    @media (--tablet) {
      @mixin lost-column 6/12 0;
    }
  }

  & .CoursesOverviewPage__title {
    @mixin h30;
    font-weight: var(--boldFontWeight);
    grid-area: title;

    @media (--tablet) {
      @mixin h10;
    }
  }

  & .CoursesOverviewPage__coursesGroupList {
    @mixin clearList;

    & .CoursesOverviewPage__coursesGroupListItem:not(:first-child) {
      margin-top: 64px;

      @media (--tablet) {
        margin-top: 96px;
      }
    }

    & .CoursesOverviewPage__coursesGroupListItemTitle {
      @mixin p30;

      @media (--tablet) {
        @mixin h40;
      }
    }

    & .CoursesOverviewPage__coursesList {
      margin-top: 24px;

      @media (--tablet) {
        margin-top: 32px;
      }
    }
  }
}
