.UserFeedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 54px;

  & a {
    text-decoration: underline;
  }
}

.UserFeedback__text {
  font-size: 1.3em;
  max-width: 30rem;
  text-align: center;
}
