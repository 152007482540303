.NoAccessToEntraIdPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (--mobileOnly) {
    font-size: 0.8em;
  }

  & p {
    font-size: 1.5em;
    max-width: 38rem;
  }

  & button {
    margin-top: 20px;
  }
}
