.CardThumbnail {
  @mixin actionOverlay;
  color: var(--textColor);
  background-color: var(--white);
  border-radius: 8px;

  @media (--tablet) {
    display: flex;
  }

  & .CardThumbnail__imageContainer {
    @mixin aspectRatio 9/16;
    overflow: hidden;
    border-radius: 8px 8px 0 0;

    @media (--tablet) {
      @mixin lost-column 3/8, 0;
      border-radius: 8px 0 0 8px;
    }

    @media (--desktop) {
      @mixin lost-column 6/12, 0;
    }

    & .CardThumbnail__image {
      object-fit: cover;
    }
  }

  & .CardThumbnail__info {
    padding: 16px 24px 32px;

    @media (--tablet) {
      flex: 1;
      padding: 24px 32px 40px 0;
    }

    @media (--desktop) {
      padding-right: 40px;
    }
  }

  & .CardThumbnail__title {
    @mixin h50;
    @mixin lineUnder 16px, var(--lightGrey), 100%;
    @mixin breakWithHyphen;
    margin-bottom: 16px;

    @media (--tablet) {
      @mixin h40;
      margin-bottom: 24px;
    }

    @media (--desktop) {
      @mixin h30;
      margin-bottom: 32px;
    }
  }
}
