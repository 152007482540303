.RettsdataReference {
  & .RettsdataReference__modal {
    --bottomPadding: 48px;

    & .RettsdataReference__modalCloseContainer {
      position: fixed;
      right: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      background: color(var(--white));
      padding: 24px 32px var(--bottomPadding);

      @media (--tablet) {
        display: none;
      }

      & .RettsdataReference__modalCloseButton {
        flex: 1;
      }
    }

    & .RettsdataReference__link {
      padding-top: 32px;

      @media (--tablet) {
        padding-top: 48px;
      }
    }
  }
}
