.ContactUs {
  padding: 48px 0 40px;

  @media (--desktop) {
    display: grid;
    grid-template-areas:
      "form . email"
      "form . .";
    grid-template-columns: 7fr 1fr 4fr;
    grid-template-rows: repeat(2, auto);
    column-gap: var(--gutter);
    padding: 80px 0 96px;
  }

  & .ContactUs__emailContent {
    font-weight: var(--boldFontWeight);
    grid-area: email;
    margin-top: 48px;
  }

  & .ContactUs__emailTitle {
    @mixin h40;

    @media (--desktop) {
      @mixin h30;
    }
  }

  & .ContactUs__email {
    @mixin h50;
    margin-top: 8px;

    @media (--desktop) {
      margin-top: 16px;
    }
  }

  & .ContactUs__form {
    display: flex;
    background-color: var(--secondaryLighten10);
    min-height: 650px;
    z-index: 1;
    padding: 48px 0 80px;
    margin-top: 64px;

    @media (--desktop) {
      grid-area: form;
      align-self: start;
      padding: 48px 32px 64px;
      border-radius: 16px;
      margin-top: 0;
    }

    @media (--belowDesktop) {
      @mixin fullWidthBackground var(--secondaryLighten10);
    }
  }
}
