.ManageUserCoursesList {
  margin-bottom: 96px;

  & .ManageUserCoursesList__activeCoursesList {
    @mixin clearList;

    & li {
      margin-bottom: 32px;
    }
  }

  &.ManageUserCoursesList--empty {
    @mixin textHeaderMargin;
  }
}
