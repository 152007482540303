.input-task {
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: none;

  & .input-task__input-field-container {
    & .input-task__input-field {
      transition: 0.3s ease-in-out;
      width: 15rem;
      font-size: 1.1em;
      padding: 8px;
      border: 2px solid var(--primaryBlack);
      border-radius: 24px;
    }

    & .input-task__input-field--correct {
      border-color: var(--colorActionSuccess);
    }

    & .input-task__input-field--incorrect {
      border-color: var(--colorActionError);
    }
  }
}

.input-task__text {
  font-weight: normal;
  font-size: 1.2em;
}

.input-task__content {
  font-weight: bolder;
  font-size: 1.5em;
}
