@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-Regular.woff2") format("woff2"),
    url("../fonts/ProximaNova-Regular.woff") format("woff");
  font-weight: var(--normalFontWeight);
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-RegularIt.woff2") format("woff2"),
    url("../fonts/ProximaNova-RegularIt.woff") format("woff");
  font-weight: var(--normalFontWeight);
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: var(--boldFontWeight);
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-BoldIt.woff2") format("woff2"), url("../fonts/ProximaNova-BoldIt.woff") format("woff");
  font-weight: var(--boldFontWeight);
  font-style: italic;
  font-display: swap;
}
