.LicensesCounter {
  display: flex;
  align-items: center;
  height: 100%;

  & .LicensesCounter__totalAmount {
    @mixin p20;
    font-weight: var(--boldFontWeight);
    margin-right: 8px;
  }

  & .LicensesCounter__consumedAmount,
  & .LicensesCounter__allowedAmount {
    @mixin p30;
  }

  & .LicensesCounter__splitLine {
    height: 24px;
    width: 1px;
    background-color: var(--secondaryLighten30);
    margin: 0 8px;
  }

  & .LicensesCounter__loader {
    @mixin p20;
    width: 150px;
  }
}
