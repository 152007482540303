.TeaserCard {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--secondaryLighten60);
  border-radius: 16px;

  & .TeaserCard__header {
    background-color: var(--secondaryLighten20);
    font-weight: var(--boldFontWeight);
    padding: 24px 32px;

    @media (--tablet) {
      padding: 24px 40px 32px;
    }

    & .TeaserCard__title {
      @mixin h40;

      @media (--tablet) {
        @mixin h30;
      }
    }

    & .TeaserCard__additionalInfo {
      @mixin p30;
      color: var(--colorActionSuccess);

      @media (--tablet) {
        @mixin h50;
      }
    }
  }

  & .TeaserCard__content {
    color: var(--secondaryBase);
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  & .TeaserCard__facts {
    flex: 1;
    padding: 40px 32px;

    @media (--tablet) {
      padding: 40px 32px 64px;
    }
  }

  & .TeaserCard__factsHeading {
    @mixin p20;
    font-weight: var(--boldFontWeight);

    @media (--tablet) {
      @mixin h50;
    }
  }

  & .TeaserCard__factsList {
    @mixin p30;
    @mixin clearList;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .TeaserCard__splitLine {
    width: 100%;
    height: 1px;
    background-color: var(--secondaryLighten40);
  }

  & .TeaserCard__buttonWrapper {
    padding: 32px;

    @media (--tablet) {
      padding: 40px 32px;
    }

    & .TeaserCard__button {
      display: flex;
      justify-content: center;
      flex: 1;

      @media (--tablet) {
        min-width: 220px;
      }
    }
  }
}
