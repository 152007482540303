.ManageCourseList {
  & .ManageCourseList__inner {
    @mixin wrapper;
  }

  & .ManageCourseList__numberOfHits {
    margin-bottom: 32px;
  }

  & .ManageCourseList__Wrapper {
    display: flex;
    margin: 8px 0 8px 0;
  }

  & .ManageCourseList__input {
    flex: 1;
    max-width: 400px;
    padding-bottom: 24px;
  }

  & .ManageCourseList__activeCoursesListWrapper {
    margin-bottom: 96px;

    &.ManageCourseList__activeCoursesListWrapper--empty {
      @mixin textHeaderMargin;
    }
  }

  & .ManageCourseList__activeCoursesList {
    @mixin clearList;

    & li {
      margin-bottom: 32px;
    }
  }

  & .ManageCourseList__availableCoursesList {
    @mixin tileThumbnailList;
  }

  & .ManageCourseList__tileThumbnailBottomContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px 16px;

    & .Button {
      flex: 1;
      min-width: 140px;
    }
  }
}
