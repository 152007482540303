.TaskPreviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  & h1 {
    margin-bottom: 56px;
  }

  & .TaskPreviewContainer__buttons {
    display: flex;
    gap: 2rem;
    margin: 12px;
  }

  & .TaskPreviewContainer__reset-button {
    background-color: var(--darkBackgroundColor);
    color: var(--white);
    border: none;
    border-radius: 12px;

    &:hover {
      cursor: pointer;
    }
  }
}
