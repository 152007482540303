.visibleAboveMobileLarge {
  display: none;

  @media (--mobileLarge) {
    display: block;
  }
}

.visibleBelowMobileLarge {
  display: block;

  @media (--mobileLarge) {
    display: none;
  }
}

.visibleAboveTablet.visibleAboveTablet {
  display: none;

  @media (--tablet) {
    display: block;
  }
}

.visibleBelowTablet.visibleBelowTablet {
  display: block;

  @media (--tablet) {
    display: none;
  }
}

.visibleAboveDesktop {
  display: none;

  @media (--desktop) {
    display: block;
  }
}

.visibleAboveMobile {
  display: block;

  @media (--mobileOnly) {
    display: none;
  }
}

.visibleOnlyOnMobile {
  display: none;

  @media (--mobileOnly) {
    display: block;
  }
}
