.EmptySection {
  @mixin aspectRatio 9/16;
  margin: 48px 0;

  @media (--tablet) {
    @mixin lost-offset 3/12;
    @mixin lost-column 6/12;
  }

  @media (--desktop) {
    @mixin lost-offset 4/12;
    @mixin lost-column 4/12;
  }

  & .EmptySection__image {
    object-fit: contain;
  }

  & .EmptySection__text {
    @mixin p10;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
