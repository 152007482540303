.HomePage {
  @mixin darkPageBase;
  padding-bottom: 0;

  & .HomePage__content {
    @mixin wrapper;
  }

  & .HomePage__collapseGroup {
    @media (--mobileOnly) {
      @mixin resetMarginTopOnFirstItem;
      margin-top: 64px;
    }
  }
}
