@define-mixin adminListItemContainer {
  padding: 16px;

  @media (--tablet) {
    padding: 18px 32px;
  }
}

@define-mixin adminListItem {
  @mixin adminListItemContainer;
  color: var(--lightTextColor);
  background-color: var(--secondaryLighten10);
  width: 100%;
  border-radius: 8px;

  @media (--mobileOnly) {
    position: relative;
  }

  @media (--tablet) {
    display: flex;
    align-items: center;
    min-height: 88px;
  }
}

@define-mixin adminListItemSpacing {
  margin-bottom: 8px;
}

@define-mixin adminListItemBaseDataUnderline {
  @media (--mobileOnly) {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--secondaryLighten30);
    margin-bottom: 16px;
  }
}

@define-mixin adminListItemBaseDataContainer {
  @media (--tablet) {
    @mixin lost-column 6/12;
  }
}

@define-mixin adminListMemberItemBaseDataContainer {
  display: flex;
  align-items: center;

  @media (--tablet) {
    @mixin lost-column 8/12;
  }
}

@define-mixin adminListItemBaseData {
  display: flex;
  align-items: center;
}

@define-mixin adminListItemBaseDataInfo {
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  @media (--tablet) {
    margin-left: 24px;
  }
}

@define-mixin adminListItemBaseDataName {
  word-break: break-all;

  @media (--desktop) {
    @mixin p40;
    font-weight: var(--boldFontWeight);
  }

  @media (--tablet) {
    @mixin p10;
  }
}

@define-mixin adminListMemberItemBaseDataEmail {
  @mixin p40;
  color: var(--secondaryLighten40);

  @media (--tablet) {
    @mixin p30;
  }
}

@define-mixin adminListItemProgress {
  @media (--tablet) {
    @mixin lost-column 5/12;
  }
}

@define-mixin adminListMemberItemStatus {
  @mixin p40;

  @media (--mobileOnly) {
    text-align: right;
  }

  @media (--tablet) {
    @mixin lost-column 2/12;
    @mixin p30;
  }

  @media (--desktop) {
    @mixin lost-column 2/12;
  }
}

@define-mixin adminListItemMoreActions {
  @media (--mobileOnly) {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  @media (--tablet) {
    @mixin lost-column 1/12;
    display: flex;
    justify-content: flex-end;
  }
}
