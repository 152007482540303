.Link {
  @mixin offsetFocus;

  &.Link--primary {
    @mixin primaryLink;
  }

  &.Link--secondary {
    @mixin secondaryLink;
  }

  &.Link--clear {
    @mixin focus;
  }

  &.Link--xLarge {
    @mixin p10;
  }

  &.Link--large {
    @mixin p20;
  }

  &.Link--medium {
    @mixin p30;
  }

  &.Link--small {
    @mixin p40;
  }

  &.Link--xSmall {
    @mixin p50;
  }

  &.Link--inherit {
    font-size: inherit;
    line-height: inherit;
  }

  &.Link--active,
  &.Link--active:hover {
    color: var(--primaryBase);
  }
}
