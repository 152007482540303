.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--mobileHeaderHeight);
  display: flex;
  z-index: 100;
  color: var(--lightTextColor);
  background-color: var(--darkBackgroundColor);
  transition: background 0.3s ease-out;

  @media (--tablet) {
    height: var(--headerHeight);
  }

  & .Header__inner {
    @mixin wrapper;
  }

  & .Header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    height: 100%;
  }

  & .Header__logos {
    display: flex;
    align-items: center;

    & .Header__logoQudos {
      width: 48px;
      fill: var(--white);

      @media (--tablet) {
        width: 80px;
      }
    }

    & .Header__splitLine {
      @mixin splitLine 8px, 16px;

      @media (--tablet) {
        @mixin splitLine 24px, 16px;
      }
    }

    & .Header__logoGyldendal {
      @mixin iconBackground svg-load("icons/gyldendal-logo-mobile.svg", fill: var(--white));
      width: 16px;
      height: 16px;

      @media (--tablet) {
        @mixin iconBackground svg-load("icons/gyldendal-logo-desktop.svg", fill: var(--white));
        width: 96px;
        height: 24px;
      }
    }
  }

  & .Header__linksWrapper {
    display: none;

    @media (--desktop) {
      display: inline-flex;
      gap: 40px;
    }

    & .Header__links {
      display: flex;
      align-items: center;
      white-space: nowrap;
      gap: 40px;
    }
  }

  & .Header__openMobileMenuWrapper {
    display: block;

    @media (--desktop) {
      display: none;
    }
  }
}
