.CourseAssignmentForGroupMember {
  @mixin adminListItem;
  background-color: var(--secondaryLighten20);
  border-radius: 0;

  @media (--mobileOnly) {
    & .UserData {
      padding: unset;
      border: unset;
    }
  }

  & .CourseAssignmentForGroupMember__userData {
    @mixin adminListItemBaseDataContainer;
    @mixin adminListItemBaseDataUnderline;
  }

  & .CourseAssignmentForGroupMember__assignmentProgress {
    @mixin adminListItemProgress;
  }
}
