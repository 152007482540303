.Exam {
  position: relative;

  & .Exam__inner {
    @mixin wrapper;
    position: relative;
    z-index: 1;
  }

  & .Exam__imageOverlay {
    @mixin heroImageOverlay;
    height: 250px;

    @media (--tablet) {
      height: min(500px, 25vw);
    }
  }
}
