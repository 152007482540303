.OptionStatus {
  position: relative;
  z-index: 1;
  min-width: 200px;
  max-width: 100%;
  padding: 16px 32px 16px 0;

  &::after {
    @mixin absoluteParentSize;
    @mixin shiftHorizontallyBy 7px;
    content: "";
    z-index: -1;
    transition: border-color 0.3s, background-color 0.3s;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
  }

  & .OptionStatus__message {
    @mixin p40;
    display: block;
    padding-left: var(--choicePaddingLeft);
    margin-top: 4px;
  }

  &.OptionStatus--correct::after {
    border-color: var(--colorActionSuccess);
  }

  &.OptionStatus--wrong::after {
    border-color: var(--colorActionError);
  }

  &.OptionStatus--shouldBeChecked {
    &::after {
      background-color: var(--colorActionSuccessLighten60);
    }

    & label::before {
      background-color: var(--white);
    }
  }

  &.OptionStatus--correct {
    & input:disabled:not(:checked) + label::after,
    & input:disabled:checked + label::after {
      background-color: var(--colorActionSuccess);
    }
  }

  &.OptionStatus--wrong {
    & input:disabled:not(:checked) + label::after,
    & input:disabled:checked + label::after {
      background-color: var(--colorActionError);
    }
  }

  &.OptionStatus--correct,
  &.OptionStatus--wrong {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 16px;
      width: var(--choicePaddingLeft);
      height: 28px;
      background-color: var(--backgroundColor);
    }
  }
}
