.ProgressBar {
  width: 100%;
  height: 6px;
  background-color: var(--lightGrey);
  border-radius: 6px;

  & .ProgressBar__filler {
    background: var(--colorActionSuccess);
    height: 100%;
    border-radius: 6px;
  }

  &.ProgressBar--warning {
    & .ProgressBar__filler {
      background: var(--colorActionWarning);
    }
  }

  &.ProgressBar--error {
    & .ProgressBar__filler {
      background: var(--colorActionError);
    }
  }
}
