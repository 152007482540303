.ExamTasks {
  @media (--tablet) {
    padding-top: 64px;
  }

  & .ExamTasks__abortExam {
    position: absolute;
    right: var(--mobileWrapperPadding);

    @media (--mobileOnly) {
      top: 48px;
    }

    @media (--tablet) {
      @mixin rtl-lost-offset 1;
      right: 0;
    }

    & .ExamTasks__abortButton {
      @media (--mobileOnly) {
        @mixin smallButton;
      }
    }
  }

  & .ExamTasks__content {
    @media (--tablet) {
      @mixin lost-offset 1/12;
      @mixin lost-column 9/12;
    }
  }

  & .ExamTasks__contentHeading {
    @media (--mobileOnly) {
      padding-top: 96px;
    }
  }

  & .ExamTasks__title {
    @mixin h50;
    font-weight: var(--boldFontWeight);
    margin: 0 80px 24px 0;
  }

  & .ExamTasks__message {
    @mixin h30;
    font-weight: var(--boldFontWeight);
    margin-bottom: 32px;

    @media (--tablet) {
      @mixin h10;
      margin-bottom: 80px;
    }
  }

  & .ExamTasks__taskList {
    margin-bottom: 48px;

    & > li {
      margin-bottom: 24px;

      &:last-child {
        margin: 0;
      }
    }
  }

  & .ExamTasks__submitButton {
    @media (--mobileOnly) {
      width: 100%;
    }

    @media (--tablet) {
      margin-left: auto;
    }
  }
}
