.Member {
  @mixin adminListItem;
  @mixin adminListItemSpacing;

  @media (--desktop) {
    display: flex;
    justify-content: space-between;
  }

  @media (--tablet) {
    display: flex;
    justify-content: space-between;
  }

  & .Member__checkbox {
    margin: auto 16px auto 0;

    @media (--tablet) {
      margin-right: 32px;
    }
  }

  & .Member__baseData {
    @mixin adminListMemberItemBaseDataContainer;
    @mixin adminListItemBaseDataUnderline;
  }

  & .Member__status {
    @mixin adminListMemberItemStatus;
  }

  & .Member__inviteStatus {
    white-space: nowrap;

    @media (--mobileFirst) {
      text-align: left;
      width: 100px;
    }

    & .Member__inviteStatus-date-text {
      font-size: 0.8em;
      align-self: center;

      @media (--mobileOnly) {
        @mixin adminListMemberItemStatus;
      }
    }
  }

  & .Member__moreActions {
    @mixin adminListItemMoreActions;
    display: flex;
    flex-direction: column;
    margin-left: auto;

    & p {
      font-size: 0.8em;
    }
  }

  & button.Member__deleteMemberButton {
    @mixin primaryLink;
    width: 100%;
    display: block;
    margin: 0 auto;
  }
}

.MoreActionsPopover__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
