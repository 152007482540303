.ExamResult {
  padding-top: 32px;

  @media (--tablet) {
    padding-top: 128px;
  }

  & .ExamResult__inner {
    color: var(--secondaryBase);
    background-color: var(--white);
    overflow: hidden;
    border-radius: 8px;
  }

  & .ExamResult__header {
    background-color: var(--backgroundColor);
    padding: 48px 32px 64px;

    @media (--mobileX) {
      padding: 48px 48px 64px;
    }

    @media (--tablet) {
      padding: 64px 0 48px;
    }
  }

  & .ExamResult__headerInner {
    text-align: center;

    @media (--tablet) {
      @mixin lost-offset 2/10;
      @mixin lost-column 6/10;
    }
  }

  & .ExamResult__imageContainer {
    @mixin aspectRatio 1/1;
    width: 50%;
    max-width: 210px;
    margin: 0 auto 24px;
  }

  & .ExamResult__title {
    @mixin h40;
    @mixin lineUnder 24px, var(--secondaryBase), 80%, 10%;
    font-weight: var(--boldFontWeight);
    margin-bottom: 14px;

    @media (--tablet) {
      @mixin h10;
      margin-bottom: 16px;
    }
  }

  & .ExamResult__description {
    @mixin p20;
    margin-bottom: 48px;

    @media (--tablet) {
      @mixin h30;
      margin-bottom: 32px;
    }
  }

  & .ExamResult__actionButtons {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (--desktop) {
      flex-direction: row;
      justify-content: center;
      gap: 24px;
    }
  }

  & .ExamResult__diplomaButton {
    @media (--desktop) {
      flex: 1;
      max-width: 250px;
    }
  }

  & .ExamResult__exitButton {
    @media (--desktop) {
      flex: 1;
      max-width: 150px;
    }
  }

  & .ExamResult__progress {
    padding: 64px 32px;

    @media (--tablet) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .ExamResult__progressRing {
      @mixin h40;
      font-weight: var(--boldFontWeight);
      width: 150px;

      @media (--mobileOnly) {
        margin: 0 auto;
      }
    }

    & .ExamResult__progressBar {
      display: none;

      @media (--tablet) {
        display: block;
        width: 320px;
        margin-left: 48px;
      }
    }

    & .ExamResult__seeAnswers {
      margin: 24px auto 0;

      @media (--tablet) {
        margin: 0 0 0 24px;
      }

      & .ExamResult__seeAnswersText {
        min-width: 80px;
      }

      & .SvgIcon {
        transform: rotateZ(270deg);
        transition: transform 0.3s;
      }

      &.ExamResult__seeAnswers--reversed .SvgIcon {
        transform: rotate(90deg);
      }
    }
  }

  & .ExamResult__form--hidden {
    display: none;
    overflow: hidden;
  }

  & .ExamResult__taskList {
    @mixin overwriteOptionStatusBackground var(--white);

    @media (--mobileOnly) {
      padding: 0 32px;
    }

    @media (--tablet) {
      @mixin lost-offset 2/12;
      @mixin lost-column 8/12;
    }
  }
}
