.StepsProgressBar {
  width: 100%;

  & .StepsProgressBar__heading {
    @mixin p50;
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    & strong {
      @mixin p30;
      margin-right: 8px;
    }
  }
}
