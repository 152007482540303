.StatusCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  position: relative;
  width: 100%;
  height: 100%;

  & .StatusCircle__wrapper {
    width: 50%;
    height: 50%;
    position: relative;
    animation: circleScale 1s infinite;
    background-color: inherit;
    border-radius: 50%;

    &.StatusCircle__wrapper--error {
      background-color: var(--colorActionError);
    }

    &.StatusCircle__wrapper--success {
      background-color: var(--colorActionSuccess);
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: inherit;
      animation: circlePulse 1s infinite;
      opacity: 0;
      border-radius: inherit;
    }
  }
}

@keyframes circlePulse {
  from {
    transform: scale(0.5, 0.5);
    opacity: 1;
  }

  to {
    transform: scale(2, 2);
    opacity: 0;
  }
}

@keyframes circleScale {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}
