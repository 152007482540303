.Picker {
  position: relative;

  @media (--tablet) {
    min-height: 200px;
  }

  & .Picker__title {
    @mixin h50;
    margin-bottom: 16px;

    @media (--tablet) {
      @mixin h40;
      margin-bottom: 24px;
    }
  }

  & .Picker__inputWrapper {
    margin-bottom: 48px;

    @media (--tablet) {
      margin-bottom: 24px;
    }
  }

  & .Picker__input {
    width: 100%;
  }

  & .Picker__summary {
    margin-bottom: 8px;

    @media (--desktop) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
    }
  }

  & .Picker__selectedCount {
    @mixin p20;
    display: none;

    @media (--desktop) {
      display: block;
    }
  }

  & .Picker__submitButton {
    width: 100%;

    @media (--desktop) {
      min-width: 280px;
      width: auto;
    }
  }
}
