.NoMembershipHeaderContent {
  display: flex;
  align-items: center;

  & .NoMembershipHeaderContent__noActiveMembershipsUserSplitLine {
    @mixin splitLine 40px, 32px;
  }
}

& .NoMembershipHeaderContent__title {
  @mixin p40;
  text-align: right;
}

& .NoMembershipHeaderContent__email {
  @mixin p30;
  font-weight: var(--boldFontWeight);
}
