.UserAndGroupPicker {
  & .UserAndGroupPicker__inputWrapper {
    margin-bottom: 24px;
  }

  & .UserAndGroupPicker__input {
    width: 100%;
  }

  & .UserAndGroupPicker__usersWrapper {
    height: 190px;
    margin-bottom: 24px;

    @media (--tablet) {
      height: 210px;
    }
  }

  & .UserAndGroupPicker__users {
    @mixin listWithCustomScroll 24px;
    height: 100%;

    & li {
      &:first-of-type .UserAndGroupPickerItem {
        padding-top: 0;
      }

      &:last-of-type .UserAndGroupPickerItem {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  & .UserAndGroupPicker__users--notFound {
    @mixin h50;
    text-align: center;
    font-weight: var(--boldFontWeight);
  }
}
