@define-mixin clearList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@define-mixin listWithCustomScroll $paddingRight, $overflow: auto {
  overflow-y: $overflow;
  padding-right: calc($paddingRight + 8px);

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    width: 8px;
    height: 0;
    background-color: var(--lightGrey);
    border-radius: 80px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--darkBackgroundColor);
    border-radius: 80px;
  }
}

@define-mixin tileThumbnailList {
  @mixin clearList;

  @media (--tablet) {
    display: flex;
    flex-wrap: wrap;
  }

  & li {
    margin-bottom: 24px;

    @media (--tablet) {
      @mixin lost-column 6/12 2;
      @mixin thumbnailListItem;
      margin-bottom: 32px;
    }

    @media (--desktop) {
      @mixin lost-column 4/12 3;
    }
  }
}

@define-mixin thumbnailListItem {
  & > * {
    height: 100%;
  }
}
