& .GroupListItem {
  display: flex;
  align-items: flex-start;
  flex: 1;
  background-color: var(--secondaryLighten10);
  list-style: none;
  padding: 16px;
  border-radius: 8px;
  margin: 6px;

  & h3 {
    font-size: 0.5em;
  }

  & .GroupListItem__content {
    display: flex;
    gap: 1.2rem;
    width: 100%;
    position: relative;

    & button {
      height: 40px;
      align-self: center;
      padding: 0 6px 0 6px;
      border-radius: 6px;
      margin-left: auto;
    }

    & span:not(.MyLoader) {
      position: absolute;
      right: 0;
      align-self: center;
      color: var(--white);
      padding: 0;
      margin-right: 25px;
      margin-left: auto;

      @media (--mobileOnly) {
        right: -12px;
      }
    }

    & h3 {
      font-size: 1.1em;
    }
  }

  & .GroupListItem__avatar {
    width: 40px;
    height: 40px;
    align-self: center;
    flex-shrink: 0;

    @media (--mobileOnly) {
      width: 30px;
      height: 30px;
    }
  }

  & .GroupListItem__add-button {
    @media (--mobileOnly) {
      max-height: 30px;
    }
  }

  & .GroupListItem__info {
    display: flex;
    flex-wrap: wrap;
    min-width: 0;
    flex-grow: 1;
    flex-shrink: 1;

    & .GroupListItem__name {
      color: var(--white);
      align-self: center;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;

      @media (--smallPhone) {
        max-width: 10vw;
        font-size: 0.9em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      @media (--mediumPhone) {
        max-width: 15vw;
        font-size: 1em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      @media (--mediumPhonePlus) {
        max-width: 15vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      @media (--largePhone) {
        max-width: 20vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

@keyframes MyLoaderRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.MyLoader {
  width: 20px;
  height: 20px;
  animation: MyLoaderRotate 0.8s linear infinite;
  position: absolute;
  right: 35px;
  top: 8px;
  border: 2px solid var(--primaryBase);
  border-top-color: transparent;
  border-radius: 50%;

  @media (--mobileOnly) {
    right: 24px;
  }
}
