.CoursePrice {
  & .CoursePrice__availableCourse {
    display: flex;
    gap: 8px;
    align-items: flex-end;

    & .CoursePrice__price {
      @mixin h20;
      font-weight: var(--boldFontWeight);
      color: var(--colorNotificationSuccess);

      @media (--tablet) {
        @mixin h40;
      }
    }

    & .CoursePrice__message {
      @mixin p50;

      @media (--tablet) {
        @mixin p30;
      }
    }
  }

  & .CoursePrice__unavailableCourse {
    @mixin p50;
    color: var(--colorActionError);

    @media (--tablet) {
      @mixin p30;
    }
  }
}
