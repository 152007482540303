.GroupCourseAssignmentMembersList {
  color: var(--lightTextColor);
  background-color: var(--secondaryLighten20);

  & .GroupCourseAssignmentMembersList__membersList {
    @mixin clearList;

    & li:not(:first-child) {
      border-top: 1px solid var(--secondaryLighten30);
    }
  }

  & .GroupCourseAssignmentMembersList__loadMoreWrapper {
    @mixin adminListItem;
    background-color: transparent;
    border-top: 1px solid var(--secondaryLighten30);

    @media (--mobileOnly) {
      display: flex;
      justify-content: center;
    }
  }
}
