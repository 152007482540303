.ExitConfirmation {
  & .ExitConfirmation__title {
    @mixin h40;
    @mixin lineUnder 16px, var(--secondaryLighten40), 15%;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 24px, var(--secondaryLighten40), 50%;
    }
  }

  & .ExitConfirmation__description {
    @mixin p30;
    margin-top: 24px;

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .ExitConfirmation__actions {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;

    @media (--desktop) {
      flex-direction: row;
      gap: 16px;
      margin-top: 40px;
    }
  }
}
