@define-mixin activeTab $color {
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 8px;
    left: 0;
    bottom: 0;
    background-color: $color;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
}
