.PendingInvitationCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondaryLighten20);
  padding: 24px 32px;
  border-radius: 8px;

  & .PendingInvitationCard__invitationInfo {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  & .PendingInvitationCard__companyName {
    @mixin h50;
    font-weight: var(--boldFontWeight);
  }

  & .PendingInvitationCard__invitationActiveTo {
    @mixin p30;
  }
}
