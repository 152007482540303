@define-mixin visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  padding: 0;
  border: 0;
}

@define-mixin aspectRatio $ratio {
  position: relative;

  &::after {
    display: block;
    content: "";
    padding-bottom: calc(100% * $(ratio));
  }

  & > * {
    @mixin absoluteParentSize;
  }
}

@define-mixin actionOverlay {
  position: relative;

  & a[class*="__actionOverlay"],
  & button[class*="__actionOverlay"] {
    @mixin absoluteParentSize;
    z-index: 10;
    border: none;
    border-radius: inherit;
  }
}

@define-mixin fullWidthBackground $color {
  position: relative;
  background-color: $color;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(-50vw + 50%);
    height: 100%;
    width: 100vw;
    z-index: -1;
    background-color: $color;
  }
}

@define-mixin absoluteParentSize {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@define-mixin fixedFullSize {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@define-mixin lineUnder $bottomSpacing: 24px, $color: var(--white), $width: 60%, $left: 0, $height: 1px {
  position: relative;
  display: inline-block;
  padding-bottom: $bottomSpacing;

  &::after {
    content: "";
    position: absolute;
    left: $left;
    bottom: 0;
    height: $height;
    width: $width;
    background-color: $color;
  }
}

@define-mixin shiftBy $horizontalValue, $verticalValue {
  transform: translateX($horizontalValue) translateY($verticalValue);
  padding: 0 $horizontalValue $verticalValue 0;
  margin: 0 calc(-1 * $horizontalValue) calc(-1 * $verticalValue) 0;
}

@define-mixin shiftHorizontallyBy $value {
  transform: translateX($value);
  padding-right: $value;
  margin-right: calc(-1 * $value);
}

@define-mixin iconBackground $backgroundImage {
  background-image: $backgroundImage;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@define-mixin resetMarginTopOnFirstItem {
  &:first-child {
    margin-top: 0;
  }
}

@define-mixin splitLine $height: 24px, $spacing: 24px, $backgroundColor: var(--secondaryLighten30) {
  height: $height;
  background-color: $backgroundColor;
  width: 1px;
  margin: 0 $spacing;
}

@define-mixin lineAfter $leftSpacing: 24px, $color: var(--secondaryLighten30), $mobileDisplay: block {
  display: flex;
  align-items: center;

  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: $color;
    margin-left: $leftSpacing;

    @media (--mobileOnly) {
      display: $mobileDisplay;
    }
  }
}

@define-mixin unclickableElement {
  pointer-events: none;
}
