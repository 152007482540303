.MobileMenu {
  @mixin fixedFullSize;

  & .MobileMenu__backgroundCloseButton.MobileMenu__backgroundCloseButton {
    @mixin fixedFullSize;
    display: block;
    background-color: rgba(33, 33, 33, 0.33);
    z-index: -1;
  }

  & .MobileMenu__outer {
    @mixin fixedFullSize;
    background-color: var(--secondaryBase);
    overflow-y: auto;
    border-bottom-left-radius: 96px;

    @media (--tablet) {
      width: 50vw;
      left: auto;
      right: 0;
    }
  }

  & .MobileMenu__links {
    @mixin h40;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 24px;
    margin-top: 48px;
  }

  & .MobileMenu__panel {
    margin: 0 var(--mobileGutter);

    @media (--tablet) {
      margin: 0 var(--gutter);
    }
  }

  & .MobileMenu__closeMenuButtonWrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-top: 8px;

    @media (--tablet) {
      padding: 0 24px;
      margin-top: 16px;
    }
  }
}
