.Label {
  @mixin label;

  &.Label--small {
    @mixin p30;
    font-weight: var(--normalFontWeight);
  }

  &.Label--big {
    @mixin h50;
  }

  &.Label--withHiddenLabel {
    @mixin visuallyHidden;
  }

  & .Label__requiredMark {
    color: var(--colorNotificationWarning);
  }
}
