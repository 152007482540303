.NotStartedCourseList {
  margin-bottom: 96px;

  &.NotStartedCourseList--empty {
    @mixin textHeaderMargin;
  }

  & .NotStartedCourseList__list {
    @mixin tileThumbnailList;
  }

  & .NotStartedCourseList__modal {
    & .NotStartedCourseList__modalContent p {
      margin-top: 24px;
    }

    & .NotStartedCourseList__modalButtonWrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 32px;

      @media (--desktop) {
        flex-direction: row;
        gap: 16px;
        margin-top: 40px;
      }
    }
  }
}
