.UserDataItem {
  text-align: center;

  & .UserDataItem__label {
    @mixin p30;
    color: var(--primaryBase);
  }

  & .UserDataItem__value {
    @mixin p20;

    @media (--tablet) {
      @mixin p10;
    }
  }
}
