.TeaserList {
  padding: 40px 0 64px;

  @media (--desktop) {
    padding: 64px 0 96px;
  }

  & .TeaserList__list {
    @mixin clearList;

    @media (--desktop) {
      display: flex;
      flex-wrap: wrap;
      row-gap: 40px;
    }
  }

  & .TeaserList__item {
    margin-top: 24px;

    @media (--belowDesktop) {
      @mixin resetMarginTopOnFirstItem;
    }

    @media (--desktop) {
      @mixin lost-column 6/12 2;
      margin-top: 0;
    }
  }
}
