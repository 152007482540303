.Checkbox {
  --checkbox: url("../../static/images/icons/checkbox.svg");
  --checkedCheckbox: url("../../static/images/icons/checkedCheckbox.svg");
  --check: url("../../static/images/icons/check.svg");
  position: relative;
  display: flex;

  & .Checkbox__label {
    @mixin shiftPseudoElementsVertically 4px;
    display: inline-block;

    & .Checkbox__labelContent {
      display: inline-block;
    }
  }

  & .Checkbox__input {
    @mixin choice transparent, var(--colorActionSuccess);
  }

  &.Checkbox--withHiddenLabel {
    & .Checkbox__label {
      @mixin shiftPseudoElementsVertically 0;
    }

    & .Checkbox__labelContent {
      width: 0;
      height: 0;
      visibility: hidden;
      overflow: hidden;
    }

    & .Checkbox__input {
      @mixin choiceWithHiddenLabel;
    }
  }

  &.Checkbox--light {
    & .Checkbox__input:not(:checked) + label {
      &::before {
        background-color: var(--secondaryLighten40);
        mask: var(--checkbox) no-repeat;
        width: 20px;
        height: 20px;
      }
    }

    & .Checkbox__input:checked + label {
      &::before {
        background-color: var(--secondaryLighten40);
        mask: var(--checkedCheckbox) no-repeat;
        width: 20px;
        height: 20px;
      }
    }

    & .Checkbox__input:not(:checked) + label,
    & .Checkbox__input:checked + label {
      &::after {
        background-color: var(--colorActionSuccess);
        mask: var(--check) no-repeat;
        width: 100%;
        height: 100%;
      }
    }
  }

  &.Checkbox--dark {
    & .Checkbox__input:not(:checked) + label {
      &::before {
        background-color: var(--secondaryLighten30);
        mask: var(--checkbox) no-repeat;
        width: 20px;
        height: 20px;
      }
    }

    & .Checkbox__input:checked + label {
      &::before {
        background-color: var(--secondaryLighten30);
        mask: var(--checkedCheckbox) no-repeat;
        width: 20px;
        height: 20px;
      }
    }

    & .Checkbox__input:not(:checked) + label,
    & .Checkbox__input:checked + label {
      &::after {
        background-color: var(--colorActionSuccess);
        mask: var(--check) no-repeat;
        width: 100%;
        height: 100%;
      }
    }
  }
}
