.CourseBrowser {
  & .CourseBrowser__heading {
    background-color: var(--darkBackgroundColor);
    color: var(--lightTextColor);
    padding-top: 25px;

    & .CourseBrowser__courseInfoLink {
      @mixin p30;
      display: flex;
      margin-bottom: 24px;
    }
  }

  & .CourseBrowser__content {
    @mixin wrapper;
    position: relative;
    padding-top: 64px;
    padding-bottom: 200px;

    @media (--desktop) {
      display: flex;
    }

    & .CourseBrowser__headerSkip {
      position: absolute;
      top: calc(-1 * var(--headerHeight));
    }
  }

  & .CourseBrowser__activeElement {
    @media (--desktop) {
      @mixin lost-column 8/12;
    }
  }

  & .CourseBrowser__navigationButtons {
    padding-top: 32px;
    border-top: 1px solid var(--lightGrey);
  }

  & .CourseBrowser__moduleNavigation {
    @media (--desktop) {
      @mixin lost-column 4/12;
    }

    & .CourseBrowser__moduleNavigationInner {
      @media (--desktop) {
        position: sticky;
        top: calc(16px + var(--headerHeight));
        left: 0;
      }
    }

    & .CourseBrowser__chapterList {
      margin: 24px 0;

      @media (--desktop) {
        margin: 0 0 24px;
      }
    }

    & .CourseBrowser__testLink {
      min-height: 56px;

      @media (--IE11) {
        min-height: 0;
      }
    }
  }
}
