.TextArea {
  display: block;

  & .TextArea__control {
    @mixin resetInputStyles;
    @mixin p20;
    @mixin focus;
    height: 192px;
    position: relative;
    cursor: text;
    width: 100%;
    resize: none;
    font-family: var(--baseFontFamily);
    border-radius: 24px;

    &.TextArea__control--resizable {
      resize: vertical;
    }

    &.TextArea__control--error.TextArea__control--error {
      border-color: var(--colorNotificationError);
    }

    &.TextArea__control--readOnly,
    &.TextArea__control--disabled {
      opacity: 0.6;
      cursor: default;
    }
  }

  & .TextArea__label {
    @mixin label;

    &.TextArea__label--hidden {
      @mixin visuallyHidden;
    }
  }

  &.TextArea--dark {
    & .TextArea__control {
      @mixin darkFieldBase;
    }
  }

  &.TextArea--light {
    & .TextArea__control {
      @mixin lightFieldBase;
    }
  }
}
