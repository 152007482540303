.match-task-card-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px;
  justify-items: center;
}

.match-task-card-container__card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: var(--boxShadowCard);
  padding: 12px;
  border-radius: 12px;
  margin: 12px;
}

.match-task-card-container__card-radio-container {
  display: flex;
  gap: 24px;
  padding: 12px;

  & input {
    transform: scale(1.5);
  }
}

.match-task-card-container__card-question {
  font-weight: bolder;
}

.match-task-card-container__card-radio-container--correct {
  border: 2px solid var(--colorActionSuccess);
  border-radius: 12px;
}

.match-task-card-container__card-radio-container--incorrect {
  border: 2px solid var(--colorActionError);
  border-radius: 12px;
}

.match-task-card-container__card-radio-container--should-have-chosen {
  background-color: var(--colorActionSuccessLighten60);
  border-radius: 12px;
}

.match-task-card-container__card-answer-status-text {
  font-size: 0.9em;
}
