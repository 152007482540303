/* stylelint-disable property-disallowed-list */

@import-normalize;

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: var(--baseFontSize);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--backgroundColor);
  color: var(--textColor);
  font-family: var(--baseFontFamily);
  font-weight: var(--normalFontWeight);
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.5;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  margin: 0;

  &.scrollLocked {
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
  }
}

main {
  display: block;
  width: 100%;
  min-height: calc(100vh - var(--footerMinHeight) + 1px);
}

#mainContent {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: var(--mobileHeaderHeight);

  @media (--tablet) {
    padding-top: var(--headerHeight);
  }

  & > * {
    width: 100%;
  }
}

button,
input {
  &:focus:not(.focus-visible) {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.stickyHeader {
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
}

/* stylelint-enable property-disallowed-list */
