.PortableTextContent {
  @mixin p30;

  @media (--tablet) {
    @mixin p10;
  }

  /* stylelint-disable no-descending-specificity */

  & > *,
  & > div > * {
    @mixin resetMarginTopOnFirstItem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  /* stylelint-enable no-descending-specificity */

  & h2 {
    @mixin h30;
  }

  & h3 {
    @mixin h40;
  }

  & h2,
  & h3 {
    @mixin breakWithHyphen;
    margin-bottom: 24px;
  }

  & p {
    margin: 1em 0;
  }

  & a {
    @mixin focus;
    @mixin breakLink;
    color: var(--primaryDarken20);
    font-weight: var(--boldFontWeight);
  }

  & .PortableTextContent__vimeoPlayer {
    margin-bottom: 24px;

    @media (--tablet) {
      margin-bottom: 64px;
    }
  }

  & .PortableTextContent__highlightBlock {
    margin-bottom: 24px;

    @media (--tablet) {
      @mixin lost-column 7/8 0;
      margin-bottom: 64px;
    }
  }

  & .PortableTextContent__imageWrapper {
    width: 100%;
    margin-bottom: 24px;

    @media (--tablet) {
      margin-bottom: 54px;
    }

    & figure {
      margin: 0;

      & img {
        width: 100%;
      }
    }

    &.PortableTextContent__imageWrapper--extraLarge {
      @media (--tablet) {
        @mixin lost-column 8/8;
      }
    }

    &.PortableTextContent__imageWrapper--large {
      @media (--tablet) {
        @mixin lost-column 7/8 0;
      }
    }

    &.PortableTextContent__imageWrapper--medium {
      @media (--tablet) {
        @mixin lost-column 6/8 0;
      }
    }

    &.PortableTextContent__imageWrapper--small {
      @media (--tablet) {
        @mixin lost-column 4/8 0;
      }
    }

    &.PortableTextContent__imageWrapper--ratio {
      position: relative;

      & img {
        @mixin absoluteParentSize;
      }
    }
  }
}
