@define-mixin label {
  @mixin h50;
  font-weight: var(--boldFontWeight);
  display: block;
  margin-bottom: 8px;
}

@define-mixin fieldBase $backgroundColor: var(--white), $borderColor: var(--secondaryLighten40),
  $textColor: var(--textColor), $borderHoverColor: var(--white) {
  background-color: $backgroundColor;
  display: flex;
  align-items: center;
  color: $textColor;
  transition: border-color 0.2s;
  padding: 8px 24px;
  border: 2px solid $borderColor;
  border-radius: 24px;

  &:hover {
    border-color: $borderHoverColor;
  }

  & input {
    &::placeholder {
      color: var(--secondaryLighten40);
    }
  }

  &.Input--disabled {
    &:hover {
      border-color: $borderColor;
    }
  }

  & .SvgIcon {
    stroke: var(--primaryBase);

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}

@define-mixin darkFieldBase {
  @mixin fieldBase var(--secondaryBase), var(--secondaryLighten40), var(--white);
}

@define-mixin lightFieldBase {
  @mixin fieldBase var(--white), var(--secondaryLighten40), var(--secondaryBase), var(--secondaryBase);
}

@define-mixin resetInputStyles {
  color: inherit;
  background: none;
  outline: none;
  padding: 0;
  border: none;

  &::placeholder {
    color: var(--secondaryLighten40);
  }
}

@define-mixin errorMessage $color, $topSpacing: 0 {
  color: $color;
  margin-top: $topSpacing;
}
