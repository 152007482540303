.ChapterList {
  display: flex;
  background-color: var(--white);
  box-shadow: 0 0 40px rgba(15, 43, 59, 0.1);
  padding: 16px 24px;
  border-radius: 8px;

  @media (--tablet) {
    max-height: min(512px, calc(100vh - var(--headerHeight) - 56px - 32px));
  }

  & .ChapterList__chapter {
    padding: 16px 0;
    border-bottom: 1px solid var(--neutralGrey);

    & .ChapterList__chapterLink {
      @mixin resetFocus;
      display: inline;
      transition: font-size 0.3s ease-out;

      &.focus-visible:focus {
        @mixin offsetFocus -4px;
        padding: 4px;
      }
    }

    &.ChapterList__chapter--active {
      & .ChapterList__chapterLink {
        @mixin h50;
        font-weight: var(--boldFontWeight);
      }
    }
  }

  & .ChapterList__chapters {
    @mixin listWithCustomScroll 24px;
    width: 100%;

    @media (--IE11) and (--tablet) {
      max-height: 512px;
    }

    & li {
      &:last-of-type .ChapterList__chapter {
        border-bottom: none;
      }
    }
  }
}
