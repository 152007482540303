.CourseAssignmentList {
  & .EmptySection {
    padding-top: 32px;
  }

  & .CourseAssignmentList__title {
    @mixin h50;
    margin-bottom: 24px;

    @media (--tablet) {
      @mixin h30;
    }
  }

  & .CourseAssignmentList__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    @media (--tablet) {
      margin-bottom: 48px;
    }

    & .CourseAssignmentList__button.CourseAssignmentList__button {
      margin-left: auto;

      @media (--mobileOnly) {
        @mixin primaryCircleButton;
        padding: 12px;
      }
    }

    & .CourseAssignmentList__buttonLeftIcon {
      @media (--mobileOnly) {
        display: none;
      }
    }

    & .CourseAssignmentList__buttonText {
      @media (--mobileOnly) {
        @mixin visuallyHidden;
      }
    }

    & .CourseAssignmentList__buttonRightIcon {
      @media (--tablet) {
        display: none;
      }
    }
  }

  & .CourseAssignmentList__loadMoreButton {
    margin: 32px auto 0;

    @media (--tablet) {
      margin-top: 48px;
    }
  }

  & .CourseAssignmentList__filterExpiredCourseAssignments {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    padding: 12px 0;

    & label::before {
      margin: 0;
    }
  }
}
