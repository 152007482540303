.ContactUsPage {
  @mixin darkPageBase;
  padding-top: 80px;

  & .ContactUsPage__wrapper {
    @mixin wrapper;
  }

  & .ContactUsPage__header {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "title"
      "clientTarget"
      "description";
    column-gap: var(--gutter);

    @media (--desktop) {
      grid-template-columns: 7fr 1fr 4fr;
      grid-template-rows: repeat(2, auto);
      grid-template-areas:
        "title . clientTarget"
        "description . clientTarget";
    }
  }

  & .ContactUsPage__title {
    @mixin h30;
    @mixin lineUnder 16px;
    grid-area: title;

    @media (--desktop) {
      @mixin h10;
      @mixin lineUnder 32px;
    }
  }

  & .ContactUsPage__description {
    @mixin p10;
    grid-area: description;
    margin-top: 80px;

    @media (--tablet) {
      @mixin h40;
    }
  }

  & .ContactUsPage__clientTargetSection {
    grid-area: clientTarget;
    margin-top: 64px;

    @media (--desktop) {
      margin-top: 0;
    }

    & .ContactUsPage__clientTargetSectionTitle {
      @mixin h50;

      @media (--desktop) {
        @mixin h40;
      }
    }

    & .ContactUsPage__clientTargetSectionMessagesList {
      @mixin clearList;
    }

    & .ContactUsPage__clientTargetSectionMessagesListItem {
      margin-top: 24px;

      & .ContactUsPage__clientTargetSectionMessageIcon {
        stroke: var(--colorActionSuccess);
      }
    }

    & .ContactUsPage__clientTargetSectionMessageText {
      @mixin p20;

      @media (--desktop) {
        @mixin p10;
      }
    }

    & .ContactUsPage__contactForm {
      grid-area: contactForm;
    }
  }
}
