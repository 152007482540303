.Radio {
  position: relative;

  & .Radio__label {
    @mixin shiftPseudoElementsVertically 4px;
    display: inline-block;

    & .Radio__labelContent {
      display: inline-block;
    }
  }

  & .Radio__input {
    @mixin choice var(--secondaryGrey), var(--secondaryBase);

    & + label {
      &::before,
      &::after {
        border-radius: 50%;
      }

      &::after {
        transform: translate(0, 0) !important;
      }
    }
  }
}
