.CourseOverviewPage {
  & .CourseOverviewPage__header {
    @mixin darkPageBase;
    padding: 0;
    margin-bottom: 32px;

    @media (--desktop) {
      margin-bottom: 0;
    }

    & .CourseOverviewPage__headerContent {
      @mixin wrapper;
      display: grid;
      grid-template-columns: 1fr;
      position: relative;
      min-height: 315px;

      @media (--desktop) {
        min-height: auto;
        grid-template-columns: 1fr 1fr;
      }
    }

    & .CourseOverviewPage__title {
      @mixin h40;
      font-weight: var(--boldFontWeight);
      z-index: 1;
      padding-top: 64px;
      padding-bottom: 120px;

      @media (--desktop) {
        @mixin h10;
        padding-top: 96px;
        padding-bottom: 96px;
      }
    }

    & .CourseOverviewPage__headerImageWrapper {
      @mixin absoluteParentSize;

      @media (--desktop) {
        @mixin aspectRatio 2/3;
        margin: 64px 0 -128px;
      }
    }

    & .CourseOverviewPage__headerImage {
      @mixin absoluteParentSize;
      object-fit: cover;
      opacity: 0.3;

      @media (--desktop) {
        opacity: 1;
        border-radius: 16px 16px 16px 48px;
      }
    }
  }

  & .CourseOverviewPage__content {
    @mixin wrapper;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "infoTable"
      "contentTitle"
      "description"
      "allCoursesButton";
    padding-bottom: 120px;

    @media (--desktop) {
      grid-template-columns: repeat(3, 4fr);
      grid-template-areas:
        "contentTitle contentTitle contentTitle"
        "description description infoTable"
        "allCoursesButton . infoTable";
      column-gap: var(--gutter);
      padding-bottom: 160px;
    }
  }

  & .CourseOverviewPage__contentTitle {
    @mixin lineAfter;
    @mixin h40;
    grid-area: contentTitle;
    font-weight: var(--boldFontWeight);
    margin: 48px 0 40px;

    @media (--desktop) {
      @mixin h30;
      margin: 128px 0 48px;
    }
  }

  & .CourseOverviewPage__productDataWrapper {
    grid-area: infoTable;

    @media (--belowDesktop) {
      margin-top: -104px;
    }
  }

  & .CourseOverviewPage__description {
    @mixin p10;
    grid-area: description;
    white-space: pre-line;
  }

  & .CourseOverviewPage__seeCoursesButton {
    @mixin mediumButton;
    grid-area: allCoursesButton;
    align-self: end;
    margin-top: 64px;

    @media (--desktop) {
      max-width: fit-content;
    }
  }
}
