.UserAndGroupPickerItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid var(--neutralGrey);

  @media (--mobileLarge) {
    & .UserAndGroupPickerItem__emailParent {
      width: 14rem;
    }

    & .UserAndGroupPickerItem__email {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      margin-left: 16px;
    }
  }

  & .UserAndGroupPickerItem__data {
    display: flex;
    align-items: center;
  }

  & .UserAndGroupPickerItem__fullName {
    font-weight: var(--boldFontWeight);
    margin-left: 16px;
  }

  & .UserAndGroupPickerItem__email {
    opacity: 0.7;
    font-weight: var(--normalFontWeight);
    margin-left: 16px;
  }
}

.UserAndGroupPickerItem__loadMoreWrapper {
  padding: 8px 0;

  & .UserAndGroupPicker__loadMoreButton.UserAndGroupPicker__loadMoreButton.UserAndGroupPicker__loadMoreButton {
    @mixin secondaryOutlineButton;
    margin: 0 auto;
  }
}
