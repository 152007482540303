.GroupCourseAssignmentItem {
  --itemRadius: 8px;
  margin-bottom: 8px;

  & .GroupCourseAssignmentItem__item {
    @mixin adminListItem;
    border-radius: var(--itemRadius) var(--itemRadius) 0 0;
  }

  & .GroupCourseAssignmentItem__groupData {
    @mixin adminListItemBaseDataContainer;
    @mixin adminListItemBaseDataUnderline;

    @media (--tablet) {
      @mixin actionOverlay;
      display: flex;
      align-items: center;
    }
  }

  & .GroupCourseAssignmentItem__groupDataIcon {
    transition: transform 0.3s;

    @media (--tablet) {
      margin-left: 24px;
    }

    &.GroupCourseAssignmentItem__groupDataIcon--rotated {
      transform: rotateZ(180deg);
    }
  }

  & .GroupCourseAssignmentItem__assignmentProgress {
    @mixin adminListItemProgress;
  }

  & .GroupCourseAssignmentItem__moreActions {
    @mixin adminListItemMoreActions;
  }

  & .GroupCourseAssignmentItem__membersList {
    overflow: hidden;
    border-radius: 0 0 var(--itemRadius) var(--itemRadius);
  }

  & .GroupCourseAssignmentItem__showMembersButton.GroupCourseAssignmentItem__showMembersButton {
    @mixin p40;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--lightTextColor);
    background-color: var(--secondaryLighten20);
    padding: 16px;

    @media (--tablet) {
      display: none;
    }

    &.GroupCourseAssignmentItem__showMembersButton--listOpened {
      border-bottom: 1px solid var(--secondaryLighten30);
    }
  }
}
