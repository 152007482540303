.ActionConfirmationPanel {
  & .ActionConfirmationPanel__header {
    @mixin breakWithoutHyphen;
    @mixin h40;
    @mixin lineUnder 16px, var(--secondaryLighten40), 15%;
    font-weight: var(--boldFontWeight);

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 24px, var(--secondaryLighten40), 50%;
    }
  }

  & .ActionConfirmationPanel__content {
    @mixin p30;
    margin-top: 24px;

    @media (--tablet) {
      @mixin p10;
      margin-top: 40px;
    }
  }

  & .ActionConfirmationPanel__buttons {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;

    @media (--desktop) {
      flex-direction: row;
      gap: 40px;
      margin-top: 40px;
    }
  }

  & .ActionConfirmationPanel__loader {
    background-color: var(--white);
    border-radius: 24px;
  }
}
