.PaymentInfoPage {
  @mixin darkPageBase;
  display: flex;
  align-items: center;

  & .PaymentInfoPage__wrapper {
    @mixin wrapper;
    flex: 1;
  }

  & .PaymentInfoPage__inner {
    column-gap: var(--gutter);
    row-gap: var(--gutter);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, minmax(0, 1));
    grid-template-areas:
      "image"
      "text";
    margin-top: 24px;

    @media (--desktopHD) {
      grid-template-areas: "text image";
      grid-template-columns: 5fr 7fr;
    }
  }

  & .PaymentInfoPage__text {
    grid-area: text;

    & .PaymentInfoPage__title {
      @mixin h10;
      font-weight: var(--boldFontWeight);

      &.PaymentInfoPage__title--success {
        color: var(--colorActionSuccess);
      }
    }

    & .PaymentInfoPage__description {
      @mixin p10;
      font-weight: var(--normalFontWeight);
      margin-top: 16px;
    }
  }

  & .PaymentInfoPage__button {
    margin-top: 48px;

    @media (--desktopHD) {
      width: fit-content;
    }
  }

  & .PaymentInfoPage__imageContainer {
    grid-area: image;
    justify-self: center;

    @media (--desktopHD) {
      justify-self: end;
    }
  }

  & .PaymentInfoPage__image {
    display: block;
    width: 100%;
  }
}
