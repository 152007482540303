.TileThumbnailListWithFootnotes {
  & .TileThumbnailListWithFootnotes__coursesList {
    @mixin tileThumbnailList;
  }

  & .TileThumbnailListWithFootnotes__footnotesList {
    @mixin clearList;
  }

  & .TileThumbnailListWithFootnotes__footnotesListItem {
    @mixin p50;
    color: var(--secondaryLighten50);

    @media (--tablet) {
      @mixin p30;
    }
  }

  & .TileThumbnailListWithFootnotes__courseThumbnailBottomContent {
    @mixin unclickableElement;
  }
}
