.CompanyPage {
  @mixin darkPageBase;

  & .CompanyPage__content {
    @mixin wrapper;
  }

  & .CompanyPage__content__settings {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & h3 {
      font-weight: 500;
      font-size: 1.4em;
    }

    & p {
      font-size: 1.2em;
    }
  }

  & .CompanyPage__toggleButtonContainer {
    display: flex;
    width: 15rem;

    @media (--mobileOnly) {
      font-size: 1.5em;
    }
  }

  & .UpdateSettingsButton {
    margin: 24px 0;
  }

  & .DossierCourseListButton {
    font-size: 1em;
    background: none;
    text-decoration: underline;
    color: var(--white);
    width: fit-content;
    border: none;
    margin: 1em 0;

    &:hover {
      cursor: pointer;
    }
  }

  & .DossierCourseList {
    list-style: none;
    padding: 0;
    margin: 0;

    & a {
      font-size: 1.2em;
      text-decoration: underline;
    }
  }
}
