.InfoList {
  @mixin clearList;

  & li {
    padding: 16px 0 24px;

    @media (--tablet) {
      padding-top: 24px;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:first-child) {
      border-top: 1px solid var(--secondaryLighten30);
    }
  }

  & .InfoList__item {
    display: flex;
    gap: 12px;

    & .InfoList__itemIcon {
      color: var(--primaryBase);
      flex-shrink: 0;
    }

    & .InfoList__itemContent {
      flex: 1;
    }

    & .InfoList__itemTitle {
      @mixin h50;

      @media (--tablet) {
        @mixin h40;
      }
    }

    & .InfoList__itemDescription {
      @mixin p30;
      color: var(--secondaryLighten50);
      margin-top: 8px;

      @media (--tablet) {
        @mixin p20;
      }
    }
  }
}
