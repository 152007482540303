.ManageCourseInfo {
  & .ManageCourseInfo__content {
    padding: 48px 0 0;

    @media (--tablet) {
      padding: 80px 0 0;
    }
  }

  & .ManageCourseInfo__introductionWrapper {
    margin-bottom: 64px;
  }

  & .ManageCourseInfo__introduction {
    @media (--tablet) {
      @mixin lost-column 8/12;
    }

    & .ManageCourseInfo__introductionCollapse {
      & .TextCollapse__collapse {
        min-height: calc(4 * var(--baseLineHeight));
      }
    }

    & .ManageCourseInfo__introductionButton {
      @mixin primaryLink;
      margin-top: 16px;
    }
  }
}
