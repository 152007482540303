.GroupParticipantsList {
  & .GroupParticipantsList__headerButton.GroupParticipantsList__headerButton {
    @mixin primarySolidButton;

    @media (--mobileOnly) {
      @mixin primaryCircleButton;
    }

    & .GroupParticipantsList__buttonText {
      @media (--mobileOnly) {
        @mixin visuallyHidden;
      }
    }
  }

  & .GroupParticipantsList__listHeading {
    margin-bottom: 32px;

    @media (--tablet) {
      margin-bottom: 48px;
    }
  }

  & .GroupParticipantsList__loadMoreButton {
    margin: 32px auto 0;

    @media (--tablet) {
      margin-top: 48px;
    }
  }

  & .GroupParticipantsList__modalMessage {
    @mixin h40;
    font-weight: var(--boldFontWeight);
  }
}
