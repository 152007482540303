.UserProfile {
  & .UserProfile__heading {
    @mixin profileHeading;
  }

  & .UserProfile__fullName {
    @mixin profileFullName;
  }

  & .UserProfile__roles {
    @mixin p30;
    color: var(--secondaryLighten40);
    margin-bottom: 32px;
  }

  & .UserProfile__additionalInfo {
    @media (--tablet) {
      @mixin lost-column 8/12;
    }
  }

  & .UserProfile__loader {
    background-color: var(--white);
  }
}
