.MoreActionsPopover {
  & .MoreActionsPopover__popoverContent {
    & > * {
      width: 100%;
      margin-bottom: 16px;
    }

    & :last-child {
      margin-bottom: 0;
    }
  }
}

.MoreActionsPopover__moreActionsButton {
  &:disabled,
  &:disabled:hover {
    opacity: 0.4;
  }

  & svg {
    stroke: var(--white);
  }
}

.MoreActionsPopover__filterContainer {
  display: flex;
}
