@define-mixin lost-column $fraction, $cycle {
  lost-column: $fraction $cycle var(--mobileGutter);

  @media (--tablet) {
    lost-column: $fraction $cycle var(--gutter);
  }
}

@define-mixin lost-offset $fraction, $direction {
  lost-offset: $fraction $direction var(--mobileGutter);

  @media (--tablet) {
    lost-offset: $fraction $direction var(--gutter);
  }
}

@define-mixin calculate-columns $mobileColumns, $columns {
  width: calc(calc(var(--wrapperContentSize) - var(--mobileWrapperSpacing)) / calc(8 / $mobileColumns));

  @media (--tablet) {
    width: calc(calc(var(--wrapperContentSize) - var(--desktopWrapperSpacing)) / calc(12 / $columns));
  }
}

@define-mixin rtl-lost-offset $columns {
  margin-right: calc(calc(var(--wrapperContentSize) - var(--mobileWrapperSpacing)) / calc(8 / $columns));
}
