.GroupCoursesList {
  margin-bottom: 40px;

  @media (--tablet) {
    margin-bottom: 64px;
  }

  & .GroupCoursesList__list {
    @mixin clearList;

    & li {
      margin-bottom: 32px;

      @media (--tablet) {
        margin-bottom: 40px;
      }
    }
  }
}
