@keyframes LoaderRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Loader {
  @mixin absoluteParentSize;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;

  & .Loader__inner {
    @mixin aspectRatio 1/1;
    display: block;
    width: 65px;
    animation: LoaderRotate 0.8s linear infinite;
    border: 2px solid var(--primaryBase);
    border-top-color: transparent;
    border-radius: 50%;
  }
}
