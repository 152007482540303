.Input {
  display: block;

  & .Input__field {
    @mixin fieldBase;
    height: 48px;
    position: relative;
    cursor: text;

    & .Input__focusIndicator {
      @mixin absoluteParentSize;
      border-radius: 24px;
    }

    &.Input__field--error {
      border-color: var(--colorNotificationWarning);
    }

    &.Input--readOnly,
    &.Input--disabled {
      opacity: 0.6;
      cursor: default;
    }

    & input {
      @mixin resetInputStyles;
      @mixin p20;
      flex: 1;

      &.focus-visible:focus + .Input__focusIndicator {
        box-shadow: 0 0 0 2px var(--colorNotificationFocus);
        pointer-events: none;
      }
    }
  }

  & .Input__label {
    @mixin label;

    &.Input__label--hidden {
      @mixin visuallyHidden;
    }
  }

  &.Input--dark {
    & .Input__field {
      @mixin darkFieldBase;

      &.Input__field--error {
        border-color: var(--colorNotificationError);
      }
    }
  }

  &.Input--light {
    & .Input__field {
      @mixin lightFieldBase;

      &.Input__field--error {
        border-color: var(--colorNotificationError);
      }
    }
  }
}
