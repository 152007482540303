.HomeHero {
  position: relative;
  min-height: 250px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "content";

  @media (--mobileLarge) {
    display: flex;
    min-height: min(350px, 25vw);
  }

  & .HomeHero__header {
    @mixin wrapper;
    z-index: 1;
    grid-area: content;
  }

  & .HomeHero__headerContent {
    margin: 64px 0 232px 0;

    @media (--mobileLarge) {
      @mixin lost-column 10/12;
      margin: 128px 0 224px 0;
    }

    @media (--tablet) {
      @mixin lost-column 8/12;
    }

    @media (--desktop) {
      @mixin lost-column 6/12;
    }
  }

  & .HomeHero__title {
    @mixin h30;
    @mixin lineUnder 16px;
    z-index: 1;
    font-weight: var(--boldFontWeight);

    @media (--mobileLarge) {
      @mixin h10;
      @mixin lineUnder 32px;
    }
  }

  & .HomeHero__content {
    @mixin p10;
    margin-top: 32px;
  }

  & .HomeHero__linksWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;

    @media (--tablet) {
      gap: 16px;
      flex-direction: row;
      margin-top: 48px;
    }
  }

  & .HomeHero__imageOverlay {
    grid-area: content;

    @media (--belowMobileLarge) {
      @mixin aspectRatio 16/9;
    }
  }

  & .HomeHero__image {
    @mixin absoluteParentSize;
    object-fit: cover;
    opacity: 0.6;
  }
}
