.GroupMember {
  @mixin adminListItem;
  @mixin adminListItemSpacing;

  & .GroupMember__baseData {
    @mixin adminListMemberItemBaseDataContainer;
    @mixin adminListItemBaseDataUnderline;
  }

  & .GroupMember__role {
    @mixin adminListMemberItemStatus;
  }

  & .GroupMember__moreActions {
    @mixin adminListItemMoreActions;
  }
}
