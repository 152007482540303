.GroupProfile {
  & .GroupProfile__removeGroupButton.GroupProfile__removeGroupButton {
    width: 100%;
  }

  & .GroupProfile__heading {
    @mixin profileHeading;
  }

  & .GroupProfile__fullName {
    @mixin profileFullName;
    @mixin breakWithHyphen;
    @mixin lineUnder 32px, var(--secondaryLighten40), 40%, 30%;
  }

  & .GroupProfile__membersCount {
    @mixin primaryLink;
    @mixin p20;
    margin-top: 42px;
  }

  & .GroupProfile__membersList {
    margin-top: 64px;

    @media (--tablet) {
      margin-top: 96px;
    }
  }

  & .GroupProfile__loader {
    background-color: var(--white);
  }
}
