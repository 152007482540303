.MatchTaskView__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;

  @media (--mobileOnly) {
    justify-content: center;
  }

  & .MatchTaskView__reset-button {
    background-color: var(--colorNotificationFocus);
    border: none;
    border-radius: 10px;

    &:hover {
      cursor: pointer;
    }
  }
}

.MatchTaskTable {
  margin-top: 3rem;

  & .MatchTaskTable__single-header {
    max-width: 50px;
    word-break: break-word;
    text-align: center;
  }
}
