.ProductData {
  display: grid;
  grid-template-areas:
    "table"
    "infoButtons";
  row-gap: var(--gutter);

  & .ProductData__infoTable {
    background-color: var(--white);
    grid-area: table;
    filter: var(--effectShadow10);
    padding: 32px 24px 40px;
    border-radius: 16px;

    @media (--desktop) {
      min-width: 360px;
      padding: 40px 32px;
    }
  }

  & .ProductData__packageDataList {
    @mixin clearList;
    grid-area: table;

    & .ProductData__infoTableItem {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-areas: "title  value";
      column-gap: var(--mobileGutter);

      @media (--desktop) {
        column-gap: var(--gutter);
      }

      &:not(:first-child) {
        padding-top: 16px;
        border-top: 1px solid var(--secondaryLighten40);
      }

      &:not(:last-child) {
        padding-bottom: 16px;
      }
    }

    & .ProductData__tableItemTitle {
      @mixin p30;
      font-weight: var(--boldFontWeight);
      grid-area: title;

      @media (--desktop) {
        @mixin h50;
      }
    }

    & .ProductData__tableItemValue {
      @mixin p30;
      color: var(--secondaryLighten30);
      grid-area: value;
      justify-self: end;

      @media (--desktop) {
        @mixin p10;
      }
    }
  }

  & .ProductData__packagePriceWrapper {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    grid-area: price;
    margin-top: 32px;

    @media (--desktop) {
      margin-top: 64px;
    }

    & .ProductData__packagePrice {
      @mixin h20;
      color: var(--colorNotificationSuccess);
      font-weight: var(--boldFontWeight);

      @media (--desktop) {
        @mixin h10;
      }
    }

    & .ProductData__packagePriceText {
      @mixin p30;

      @media (--desktop) {
        @mixin p20;
      }
    }
  }

  & .ProductData__packageMessage {
    @mixin p30;
    color: var(--colorActionError);
    margin-top: 40px;

    @media (--desktop) {
      @mixin p10;
      margin-top: 64px;
    }
  }

  & .ProductData__infoButtons {
    display: grid;
    grid-template-rows: 24px 1fr 1fr;
    row-gap: 16px;
    grid-area: infoButtons;

    & .ProductData__paymentInfo {
      color: var(--primaryBase);
      text-align: center;
    }
  }

  & .ProductData__payLink,
  & .ProductData__secondaryLink {
    @media (--mobileOnly) {
      @mixin largeButton;
    }
  }

  & .ProductData__payLink {
    grid-row-start: 2;
    grid-row-end: 2;
  }

  & .ProductData__secondaryLink {
    grid-row-start: 3;
    grid-row-end: 3;
  }
}
