.MyCoursesPage {
  @mixin darkPageBase;

  & .MyCourses__descriptionHeading {
    @mixin p10;
    display: block;
    margin-bottom: 8px;

    @media (--tablet) {
      @mixin h40;
    }
  }
}

.SuccessMessage__outer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(1, 1, 1, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  & .SuccessMessage__inner {
    background-color: var(--white) !important;
    color: var(--primaryBlack);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 1.5em;
    padding: 20px;
    border-radius: 10px;

    @media (--mobileOnly) {
      font-size: 1em;
    }

    & .ButtonContainer {
      display: flex;
      margin-bottom: 12px;
    }

    & button {
      background: none;
      border: none;
      margin-left: auto;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
