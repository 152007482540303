.ProgressTag {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font-weight: var(--boldFontWeight);
  display: flex;
  align-items: center;

  &.ProgressTag--large {
    @mixin p30;
    padding: 8px 24px;
    border-radius: 16px 0;
  }

  &.ProgressTag--small {
    @mixin p40;
    padding: 4px 12px;
    border-radius: 8px 0;
  }

  &.ProgressTag--success {
    background-color: var(--colorActionSuccess);
    color: var(--textColor);
  }

  &.ProgressTag--warning {
    background-color: var(--colorSupport01Lighten20);
    color: var(--textColor);
  }

  &.ProgressTag--error {
    background-color: var(--colorActionError);
    color: var(--lightTextColor);
  }
}
