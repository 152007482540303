.HistoricalCard {
  color: var(--textColor);
  background-color: var(--white);
  overflow: hidden;
  border-radius: 8px;

  @media (--tablet) {
    display: flex;
  }

  & .HistoricalCard__titleContainer {
    background-color: var(--secondaryLighten60);
    padding: 16px;

    @media (--tablet) {
      @mixin lost-column 6/12 0;
      display: flex;
      align-items: center;
      padding: 24px 32px;
    }

    @media (--desktop) {
      @mixin lost-column 8/12 0;
      display: flex;
      align-items: center;
      padding: 24px 32px;
    }

    & .HistoricalCard__title {
      @mixin h50;
    }
  }

  & .HistoricalCard__resultContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px 16px 8px;

    @media (--tablet) {
      @mixin lost-column 6/12 0;
      padding: 24px 24px 8px 0;
    }

    @media (--desktop) {
      @mixin lost-column 4/12 0;
    }

    & .HistoricalCard__date {
      @mixin p30;
      margin: 0 16px 16px 0;
    }

    & .HistoricalCard__failed {
      @mixin p30;
      font-weight: var(--boldFontWeight);
      margin-bottom: 16px;
    }

    & .HistoricalCard__diplomaButton {
      @mixin p30;
      font-weight: var(--boldFontWeight);
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      & .SvgIcon {
        fill: var(--primaryBase);
        margin-left: 8px;
      }
    }
  }
}
