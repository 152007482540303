@define-mixin primaryLink {
  display: inline;
  font-weight: var(--boldFontWeight);
  transition: border-bottom-color 0.1s linear;
  border-bottom: 2px solid var(--primaryBase);

  &:hover {
    border-bottom-color: var(--primaryLighten40);
  }

  & .SvgIcon {
    &:first-child {
      margin-right: 4px;
    }

    &:last-child {
      margin-left: 4px;
    }
  }
}

@define-mixin secondaryLink {
  @mixin offsetFocus 8px;
  font-weight: var(--boldFontWeight);
  transition: color 0.1s linear;
  border-radius: 20px;

  &:hover {
    color: var(--secondaryLighten30);
  }
}
