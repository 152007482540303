.CollapseGroupItem {
  & .CollapseGroupItem__heading {
    @mixin actionOverlay;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--secondaryLighten40);
    margin-top: 16px;
  }

  & .CollapseGroupItem__title {
    @mixin p30;
    flex: 1;

    @media (--tablet) {
      @mixin h50;
    }
  }

  & .CollapseGroupItem__icon {
    color: var(--primaryBase);
    flex-shrink: 0;
    transition: transform 0.3s;

    &.CollapseGroupItem__icon--reverted {
      transform: rotate(180deg);
    }
  }

  & .CollapseGroupItem__description {
    @mixin p30;
    white-space: pre-wrap;
    padding: 24px 0 32px;

    @media (--tablet) {
      @mixin p10;
      padding: 32px 0 64px;
    }
  }
}
