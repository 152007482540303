.Notification.Notification {
  @mixin p20;
  color: var(--secondaryDarken20);
  padding: 0;
  border-radius: 8px;

  & .Notification--content {
    padding: 24px;
  }

  &.Notification--success {
    background-color: var(--colorActionSuccessLighten60);

    & .Notification__closeButton {
      background-color: var(--colorActionSuccess);

      & .Notification__closeButtonIcon {
        stroke: var(--secondaryDarken20);
      }
    }
  }

  &.Notification--failure {
    background-color: var(--primaryLighten60);

    & .Notification__closeButton {
      background-color: var(--colorActionError);

      & .Notification__closeButtonIcon {
        stroke: var(--white);
      }
    }
  }

  &.Notification--neutral {
    background-color: var(--secondaryLighten60);

    & .Notification__closeButton {
      background-color: var(--colorNotificationFocus);

      & .Notification__closeButtonIcon {
        stroke: var(--white);
      }
    }
  }

  & .Notification__closeButton.Notification__closeButton {
    padding: 16px;
    border-radius: 0 8px 8px 0;
  }
}
