.Grid {
  display: block;
  grid-gap: 40px;

  @media (--tablet) {
    display: grid;
  }

  &.Grid--mobile {
    @media (--mobileOnly) {
      display: grid;
    }
  }
}
