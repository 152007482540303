.DossierCoursePage {
  @mixin darkPageBase;

  & .DossierCoursePage__content {
    @mixin wrapper;

    & h3 {
      font-size: 1.6em;
    }

    & p {
      font-size: 1.2em;
      margin-top: 8px;
      margin-bottom: 8px;

      & a {
        text-decoration: underline;
      }
    }
  }

  & .DossierCoursePage__buttons {
    display: flex;
    gap: 20px;
    margin-top: 24px;
  }
}
