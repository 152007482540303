.AdminCourseCard {
  @mixin actionOverlay;
  color: var(--textColor);
  background-color: var(--white);
  border-radius: 16px;

  @media (--tablet) {
    display: flex;
  }

  & .AdminCourseCard__imageContainer {
    @mixin aspectRatio 2/3;
    overflow: hidden;
    border-radius: 16px 16px 0 0;

    @media (--tablet) {
      @mixin lost-column 4/12, 0;
      border-radius: 16px 0 0 16px;
    }

    & .AdminCourseCard__image {
      object-fit: cover;
    }
  }

  & .AdminCourseCard__info {
    padding: 16px 24px 32px;

    @media (--tablet) {
      @mixin lost-column 8/12, 0;
      display: grid;
      grid-template-columns: minmax(0, 1fr) 160px;
      grid-template-areas:
        "title progress"
        "description progress"
        "link progress";
      column-gap: 40px;
      padding: 32px 16px 48px 0;
    }

    @media (--desktop) {
      grid-template-columns: minmax(0, 1fr) 175px;
      padding-right: 64px;
    }
  }

  & .AdminCourseCard__info--noCourseStats {
    grid-template-columns: 1fr;
  }

  & .AdminCourseCard__title {
    @mixin p20;
    @mixin lineUnder 16px, var(--lightGrey), 100%;
    @mixin breakWithHyphen;
    margin-bottom: 24px;

    @media (--mobileOnly) {
      width: 100%;
    }

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 16px, var(--primaryGrey), 80%;
      grid-area: title;
      margin-bottom: 16px;
    }
  }

  & .AdminCourseCard__description {
    display: none;

    @media (--tablet) {
      @mixin lineClamp 3;
      grid-area: description;
      margin-bottom: 16px;
    }
  }

  & .AdminCourseCard__progress {
    @media (--mobileOnly) {
      @mixin lineUnder 24px, var(--lightGrey), 100%;
      display: flex;
      margin-bottom: 32px;
    }

    @media (--tablet) {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-area: progress;
    }
  }

  & .AdminCourseCard__progressRingWrapper {
    @mixin aspectRatio 1/1;
    width: 100px;

    @media (--tablet) {
      width: 100%;
    }

    & .ProgressRing__inner {
      padding: 16px;

      @media (--tablet) {
        padding: 24px;
      }
    }
  }

  & .AdminCourseCard__progressContent {
    text-align: center;

    & strong {
      @mixin h50;
      display: block;
      margin: 0 auto;

      @media (--tablet) {
        @mixin h30;
        margin-bottom: 4px;
      }
    }

    & span {
      display: none;

      @media (--tablet) {
        @mixin p50;
        display: block;
      }
    }
  }

  & .AdminCourseCard__mobileProgressDescription {
    display: none;

    @media (--mobileOnly) {
      display: inline-block;
      flex: 1;
      align-self: center;
      margin-left: 16px;
    }
  }

  & .AdminCourseCard__linkWrapper {
    z-index: 20;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: flex-end;

    @media (--tablet) {
      grid-area: link;
    }

    & .AdminCourseCard__manageCourseActivityButton {
      flex: 1;

      @media (--desktop) {
        flex: 0;
      }
    }

    & .GroupAdminCourseCard__closeAssignmentButton {
      flex: 1;

      @media (--desktop) {
        flex: 0;
      }
    }
  }
}
