.DropdownField {
  @mixin darkFieldBase;
  @mixin actionOverlay;

  & .DropdownField__actionOverlay:focus {
    @mixin offsetFocusInner;
  }

  & .DropdownField__content {
    @mixin p20;
    @mixin lineClamp 1;
    flex: 1;
  }

  & .DropdownField__placeholder {
    color: var(--secondaryLighten40);
  }

  & .DropdownField__errorMessage {
    @mixin errorMessage var(--colorNotificationError);
  }

  & .DropdownField__icon {
    flex-shrink: 0;
    transition: transform 0.3s;

    &.DropdownField__icon--rotated {
      transform: rotate(180deg);
    }
  }

  &.DropdownField--disabled {
    opacity: 0.6;
  }

  &.DropdownField--notValid {
    background-color: var(--colorNotificationErrorLighten60);
    color: var(--secondaryBase);
    border-color: var(--colorNotificationError);

    & .DropdownField__placeholder {
      color: var(--secondaryLighten40);
    }

    & .DropdownField__icon {
      stroke: var(--secondaryBase);
    }
  }
}
