.GroupThumbnail {
  @mixin breakWithHyphen;
  @mixin actionOverlay;
  background-color: var(--secondaryLighten10);
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  @media (--tablet) {
    height: 100%;
  }

  & .GroupThumbnail__avatar {
    @mixin largeAvatar;

    @media (--tablet) {
      @mixin xLargeAvatar;
    }

    &.GroupThumbnail__avatar--skeleton {
      height: 48px;
      border-radius: 50%;

      @media (--tablet) {
        height: 80px;
      }
    }
  }

  & .GroupThumbnail__content {
    display: flex;
    align-items: flex-start;
    flex: 1;
    gap: 16px;
    padding: 40px 32px 32px;

    & .GroupThumbnail__info {
      flex: 1;
    }

    & .GroupThumbnail__name {
      @mixin p20;
      @mixin breakWithHyphen;
      color: var(--white);
      margin-bottom: 4px;

      @media (--tablet) {
        @mixin h40;
      }
    }

    & .GroupThumbnail__membersNumber {
      @mixin p40;
      color: var(--secondaryLighten50);

      @media (--tablet) {
        @mixin p20;
      }
    }
  }

  & .GroupThumbnail__coursesStatus {
    @mixin p50;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & .GroupThumbnail__groupProperties {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 20;
    width: 100%;
    padding: 8px 16px;
    border-top: 1px solid var(--secondaryLighten30);
  }

  & .GroupThumbnail__circleContainer {
    width: 16px;
    height: 16px;
  }

  & .GroupThumbnail__groupActions {
    display: flex;
    gap: 24px;
    align-items: center;

    @media (--tablet) {
      gap: 16px;
    }

    & .GroupThumbnail__groupActionIcon {
      stroke: var(--secondaryLighten40);
      transition: stroke 250ms;
    }
  }

  & .GroupThumbnail__removeGroup,
  & .GroupThumbnail__renameGroup {
    & .GroupThumbnail__groupActionButton {
      padding: 8px;
    }

    &:hover {
      & .GroupThumbnail__groupActionIcon {
        stroke: var(--primaryBase);
      }
    }
  }
}
