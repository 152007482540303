.CourseAssignmentItemProgress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;

  & .CourseAssignmentItemProgress__assignmentProgressValue {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  & .CourseAssignmentItemProgress__assignmentProgressText {
    @mixin p30;
    min-width: 40px;
    margin-right: 16px;

    @media (--tablet) {
      margin-right: 24px;
    }
  }

  & .ProgressBar {
    width: 140px;

    @media (--desktop) {
      width: 160px;
    }
  }

  & .CourseAssignmentItemProgress__assignmentStatusWrapper--error {
    color: var(--colorActionError);
  }

  & .CourseAssignmentItemProgress__assignmentStatusWrapper--success {
    color: var(--colorActionSuccess);
  }

  & .CourseAssignmentItemProgress__assignmentStatus {
    @mixin p40;
    display: flex;
    align-items: center;

    & .SvgIcon {
      margin-right: 8px;
    }
  }
}
