.Teaser {
  background-color: var(--secondaryLighten10);
  height: 100%;
  padding: 32px 24px 48px;
  border-radius: 16px;

  @media (--desktop) {
    padding: 64px 64px 80px;
  }

  & .Teaser__subtitle {
    @mixin p30;
    color: var(--primaryBase);
    text-transform: uppercase;

    @media (--desktop) {
      @mixin h50;
    }
  }

  & .Teaser__title {
    @mixin h40;
    margin-top: 8px;

    @media (--desktop) {
      @mixin h20;
      margin-top: 16px;
    }
  }

  & .Teaser__content {
    margin-top: 40px;

    @media (--desktop) {
      margin-top: 48px;
    }
  }
}
