.CollapseGroupList {
  @mixin fullWidthBackground var(--secondaryLighten50);
  color: var(--textColor);
  z-index: 1;
  padding: 48px 0 64px;

  @media (--tablet) {
    padding: 80px 0 96px;
  }

  & .CollapseGroupList__title {
    @mixin h30;

    @media (--tablet) {
      @mixin h10;
      text-align: center;
    }
  }

  & .CollapseGroupList__content {
    margin-top: 48px;

    @media (--tablet) {
      margin-top: 64px;
    }
  }
}
