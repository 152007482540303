/* https://atomiks.github.io/tippyjs/v6/themes/#creating-a-theme */

.tippy-box {
  &.Popover {
    color: var(--textColor);
    background-color: var(--white);
    padding: 32px;
    border-radius: 24px;

    &.Popover--small {
      width: 300px;
    }

    &.Popover--medium {
      width: 400px;
    }

    & .Popover__header {
      display: flex;
      justify-content: space-between;

      & :is(h1, h2, h3, h4, h5, h6) {
        @mixin p30;
      }
    }

    & .Popover__content {
      margin-top: 24px;
    }
  }
}
