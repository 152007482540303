.ContactUsForm {
  width: 100%;

  & .ContactUsForm__title {
    @mixin h20;
  }

  & .ContactUsForm__description {
    @mixin p10;
    margin-top: 24px;
  }

  & .ContactUsForm__form {
    margin-top: 64px;
  }

  & .FormControl {
    @mixin resetMarginTopOnFirstItem;
    margin-top: 16px;
  }

  & .ContactUsForm__submitButton {
    width: 100%;
    margin-top: 24px;

    @media (--desktop) {
      width: auto;
    }
  }

  & .ContactUsForm__processingAgreementCheckbox + label {
    @mixin p20;
  }

  & .ContactUsForm__processingAgreementLabel {
    @mixin p20;
  }

  &.ContactUsForm--successNotification {
    @mixin h40;
    text-align: center;
    font-weight: var(--boldFontWeight);
    align-self: center;
  }
}
