@define-mixin resetButtonStyles {
  @mixin focus;
  @mixin p30;
  color: inherit;
  font-weight: inherit;
  cursor: pointer;
  display: block;
  background: none;
  padding: 0;
  border: none;
  border-radius: 0;

  &:disabled,
  &:disabled:hover {
    cursor: default;
  }
}

@define-mixin button $color, $hoverColor, $textColor {
  @mixin focus;
  font-weight: var(--boldFontWeight);
  color: $textColor;
  background-color: $color;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
  padding: 8px 24px;
  border: none;
  border: 2px solid $color;
  border-radius: 20px;

  &:hover,
  &.focus-visible:focus {
    background-color: $hoverColor;
    border-color: $hoverColor;
  }

  &:disabled,
  &:disabled:hover {
    opacity: 0.4;
    cursor: default;
    background-color: $color;
    border-color: $color;
  }
}

@define-mixin outlineButton $color, $hoverColor {
  @mixin button $color, $hoverColor, $color;
  background-color: transparent;

  &:hover,
  &.focus-visible:focus {
    border-color: $color;
  }

  &:disabled,
  &:disabled:hover {
    background-color: transparent;
  }
}

@define-mixin circleButton $color, $hoverColor, $textColor {
  @mixin button $color, $hoverColor, $textColor;
  padding: 9px;
  border-radius: 50%;

  & .SvgIcon {
    width: 24px;
    height: 24px;
    margin: 0 !important;
  }
}

@define-mixin primaryCircleButton {
  @mixin circleButton var(--darkBrandColor), var(--brandColor), var(--white);
}

@define-mixin primarySolidButton {
  @mixin button var(--darkBrandColor), var(--brandColor), var(--white);
}

@define-mixin secondaryOutlineButton {
  @mixin outlineButton var(--secondaryBase), var(--secondaryLighten60);
}

@define-mixin xSmallButton {
  &.Button--solid,
  &.Button--outline {
    @mixin p50;
    padding: 2px 6px;
    border-radius: 16px;
  }

  &.Button--circle {
    padding: 2px;
  }

  & .SvgIcon.SvgIcon {
    width: 16px;
    height: 16px;

    &:first-child {
      margin-right: 4px;
    }

    &:last-child {
      margin-left: 4px;
    }
  }
}

@define-mixin smallButton {
  &.Button--solid,
  &.Button--outline {
    @mixin p40;
    padding: 6px 22px;
    border-radius: 16px;
  }

  &.Button--circle {
    padding: 6px;
  }

  & .SvgIcon.SvgIcon {
    width: 16px;
    height: 16px;

    &:first-child {
      margin-right: 4px;
    }

    &:last-child {
      margin-left: 4px;
    }
  }
}

@define-mixin mediumButton {
  &.Button--solid,
  &.Button--outline {
    @mixin p30;
    padding: 6px 38px;
    border-radius: 24px;
  }

  &.Button--circle {
    padding: 6px;
  }

  & .SvgIcon.SvgIcon {
    width: 24px;
    height: 24px;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}

@define-mixin largeButton {
  &.Button--solid,
  &.Button--outline {
    @mixin p30;
    padding: 10px 46px;
    border-radius: 24px;
  }

  &.Button--circle {
    padding: 10px;
  }

  & .SvgIcon.SvgIcon {
    width: 24px;
    height: 24px;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}

@define-mixin xLargeButton {
  &.Button--solid,
  &.Button--outline {
    @mixin h50;
    padding: 16px 62px;
    border-radius: 32px;
  }

  &.Button--circle {
    padding: 18px;
  }

  & .SvgIcon.SvgIcon {
    width: 24px;
    height: 24px;

    &:first-child {
      margin-right: 16px;
    }

    &:last-child {
      margin-left: 16px;
    }
  }
}
