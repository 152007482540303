/* stylelint-disable csstools/use-nesting */

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: 1px solid var(--white);
  overflow: hidden;
  border-radius: 15px;

  &:focus {
    outline: 2px solid blue;
  }
}

.toggle-container.on_off {
  outline: none;

  &:focus {
    outline: 2px solid blue;
  }
}

.toggle-background {
  position: relative;
  width: 300px;
  height: 40px;
  background: #1b3745;
  box-sizing: border-box;
  user-select: none;
  border-radius: 20px;
}

.toggle-thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -5px;
  right: -10px;
  width: 55%;
  background: var(--white);
  z-index: 1;
  border-radius: 15px;

  &.on {
    left: auto;
  }
}

.toggle-text {
  width: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 2;

  @media (--mobileOnly) {
    font-size: 0.7em;
  }

  &.active {
    color: var(--primaryBlack);
  }

  &:not(.active) {
    color: var(--white);
  }

  &:first-child {
    left: 10px;
    right: 50%;
  }

  &:last-child {
    left: 50%;
    right: 20px;
  }
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  opacity: 0.7;
  border-radius: 15px;
  margin: 10px;

  &:focus {
    outline: 2px solid blue;
  }
}

.toggle-switch--on {
  opacity: 1;
}

.toggle-switch__group-tab {
  width: 40px;
}

.toggle-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: -10px;
  bottom: 0;
  transition: 0.4s;
  outline: 1px solid var(--white);
  border-radius: 34px;
  margin-right: 2px;
}

.toggle-slider::before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 4px;
  background-color: var(--white);
  border-radius: 50%;
}

.toggle-checkbox:checked + .toggle-slider {
  background-color: var(--white);
}

.toggle-checkbox:checked + .toggle-slider::before {
  left: auto;
  right: 2px;
  transform: none;
  background: #1b3745;
  opacity: 1;
}

.OnOffLabelText {
  font-size: 0.9em;
  line-height: 1.1em;
}

/* stylelint-enable csstools/use-nesting */
