@define-mixin wrapper {
  width: 100%;
  max-width: var(--wrapperWidth);
  padding: 0 var(--mobileWrapperPadding);
  margin-right: auto;
  margin-left: auto;

  @media (--tablet) {
    padding: 0 var(--wrapperPadding);
  }
}

@define-mixin fullWidthInWrapper {
  padding: 0 var(--mobileWrapperPadding);
  margin: 0 calc(-1 * var(--mobileWrapperPadding));

  @media (--tablet) {
    padding: 0 var(--wrapperPadding);
    margin: 0 calc(-1 * var(--wrapperPadding));
  }
}
