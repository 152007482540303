.HighlightBlock {
  position: relative;
  padding-top: 25px;

  & .HighlightBlock__iconWrapper {
    position: absolute;
    left: 16px;
    top: 0;
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primaryBase);
    border-radius: 50%;

    @media (--tablet) {
      left: 32px;
    }
  }

  & .HighlightBlock__icon {
    width: 13px;
    height: 35px;
  }

  & .HighlightBlock__content {
    padding: 32px 16px 16px;
    border: 3px solid var(--primaryBase);
    border-radius: 8px;

    @media (--tablet) {
      padding: 64px 32px 16px;
    }
  }

  &.HighlightBlock--example {
    & .HighlightBlock__iconWrapper {
      background-color: var(--secondaryBase);

      & .HighlightBlock__icon {
        width: 27px;
        height: 25px;
      }
    }

    & .HighlightBlock__content {
      border-color: var(--secondaryBase);
    }
  }

  &.HighlightBlock--reflection {
    & .HighlightBlock__iconWrapper {
      background-color: var(--primaryBlue);

      & .HighlightBlock__icon {
        width: 38px;
        height: 21px;
      }
    }

    & .HighlightBlock__content {
      border-color: var(--primaryBlue);
    }
  }
}
