.StartedCourseProgress {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .StartedCourseProgress__nextModuleThumbnail {
    display: inline-block;
    z-index: 20;
    margin-bottom: 24px;

    @media (--tablet) {
      margin-bottom: 32px;
    }
  }

  & .StartedCourseProgress__bottomContent {
    width: 100%;
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    gap: 16px 32px;
  }

  & .StartedCourseProgress__progressBar {
    flex: 1;
    min-width: 128px;
    max-width: 375px;
  }

  & .StartedCourseProgress__nextModuleButton {
    z-index: 20;
    margin-left: auto;
  }
}
