.RenameGroupModal {
  & .RenameGroupModal__content {
    display: flex;
    gap: 8px;
    flex-direction: column;

    @media (--desktop) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  & .RenameGroupModal__header {
    @mixin h40;
    margin-bottom: 32px;

    @media (--tablet) {
      @mixin h30;
      margin-bottom: 40px;
    }
  }

  & .RenameGroupModal__form {
    display: flex;
    gap: 8px;
    flex-direction: column;
    flex: 1;
  }

  & .RenameGroupModal__buttonWrapper {
    display: flex;
    margin-top: 48px;

    @media (--desktop) {
      display: block;
      margin: 0;
    }

    & .RenameGroupModal__button {
      flex: 1;
    }
  }

  & .RenameGroupModal__errorMessage {
    @mixin p40;
    @mixin errorMessage var(--colorNotificationError), 0;
    height: 16px;
  }

  & .RenameGroupModal__loader {
    background-color: var(--white);
    border-radius: 24px;
  }
}
