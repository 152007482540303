.GroupField {
  margin: 40px 0 48px;

  & .GroupField__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
    border: none;
    margin: 0;
  }
}
