.DropdownMenu {
  position: absolute;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s linear;
  z-index: 20;
  background-color: var(--white);
  color: var(--textColor);
  filter: var(--effectShadow20);
  padding: 16px 24px 8px;
  border-radius: 24px;
  margin-top: 16px;

  &.DropdownMenu--opened {
    visibility: visible;
    opacity: 1;
  }
}
