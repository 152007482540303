.TimeLeftFlag__container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 12px;
  border-radius: 8px 0 8px 0;
}

.TimeLeftFlag__clock {
  align-self: center;
  margin-right: 12px;
}

.white-clock {
  color: var(--white);
}

.bg-green {
  background-color: var(--colorActionSuccess);
}

.bg-red {
  background-color: var(--colorActionError);
}

.bg-yellow {
  background-color: var(--colorActionWarning);
}

.text-white {
  color: var(--white);
  font-weight: bold;
}

.text-black {
  color: var(--primaryBlack);
}

.TimeLeftFlag__indicator {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}

.TimeLeftFlag__status-text {
  font-weight: bold;
}
