.IndividualCourseAssignmentItem {
  @mixin adminListItem;
  @mixin adminListItemSpacing;

  & .IndividualCourseAssignmentItem__userData {
    @mixin adminListItemBaseDataContainer;
    @mixin adminListItemBaseDataUnderline;
  }

  & .IndividualCourseAssignmentItem__assignmentProgress {
    @mixin adminListItemProgress;
  }

  & .IndividualCourseAssignmentItem__moreActions {
    @mixin adminListItemMoreActions;
  }
}
