.CourseItemsList {
  @mixin tileThumbnailList;
}

.SearchAvailableCoursesContainer {
  max-width: 20rem;
  margin-bottom: 32px;
}

.SearchCount {
  margin-bottom: 32px;
}

.ExamCardTextContainer {
  & p {
    color: var(--colorActionError);
  }
}
