.Hero {
  padding-bottom: 32px;

  & .Hero__header {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 250px;

    @media (--tablet) {
      min-height: min(350px, 25vw);
    }

    & .Hero__headerContent {
      @mixin wrapper;
      z-index: 1;
    }

    & .Hero__imageOverlay {
      @mixin heroImageOverlay;
    }

    & .Hero__title {
      @mixin h20;
      @mixin lineUnder;
      z-index: 1;

      @media (--tablet) {
        @mixin h10;
      }
    }
  }

  & .Hero__description {
    @mixin wrapper;
  }

  & .Hero__descriptionInner {
    @mixin p20;
    padding-top: 32px;
    padding-bottom: 48px;

    @media (--tablet) {
      @mixin p10;
      padding-top: 48px;
      padding-bottom: 80px;
    }

    @media (--desktop) {
      @mixin lost-column 8/12;
    }
  }
}
