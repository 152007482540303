.ReportsPage {
  @mixin darkPageBase;

  & .ReportsPage__inner {
    position: relative;
    background-color: var(--secondaryLighten20);

    @media (--belowDesktop) {
      @mixin fullWidthInWrapper;
      padding-top: 32px;
      padding-bottom: 64px;
    }

    @media (--desktop) {
      @mixin lost-offset 3/12;
      @mixin lost-column 6/12;
      padding: 48px 32px 64px;
      border-radius: 16px;
    }
  }

  & .ReportsPage__button {
    @media (--mobileOnly) {
      width: 100%;
    }

    @media (--tablet) {
      margin: 0 0 0 auto;
    }
  }
}
