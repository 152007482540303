@define-mixin profileButtons {
  color: var(--white);
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

@define-mixin profileBackButton {
  display: flex;
  align-items: center;

  & .SvgIcon {
    margin-right: 4px;
  }
}

@define-mixin profileHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 48px 0;

  @media (--tablet) {
    padding: 96px 0;
  }
}

@define-mixin profileFullName {
  @mixin h30;
  margin: 32px 0 8px;

  @media (--tablet) {
    @mixin h20;
  }
}
