.TestResult {
  margin-bottom: 80px;

  & .TestResult__title {
    @mixin h10;
    color: var(--primaryLighten20);
    text-align: center;
    margin-bottom: 16px;

    @media (--tablet) {
      text-align: left;
      margin: 48px 0 16px;
    }
  }

  & .TestResult__description {
    @mixin p20;
    margin-bottom: 48px;

    @media (--mobileOnly) {
      text-align: center;
    }
  }

  & .TestResult__progress {
    margin-bottom: 48px;

    @media (--tablet) {
      display: flex;
      align-items: center;
      margin-bottom: 96px;
    }

    & .TestResult__progressRing {
      @mixin h10;
      font-weight: var(--boldFontWeight);
      width: 220px;

      @media (--mobileOnly) {
        margin: 0 auto;
      }

      @media (--tablet) {
        @mixin h20;
        width: 180px;
      }
    }

    & .TestResult__progressBar {
      display: none;

      @media (--tablet) {
        display: block;
        width: 320px;
        margin-left: 48px;
      }
    }
  }

  & .TestResult__buttonsContainer {
    display: flex;
    gap: 24px;
  }

  & .TestResult__button {
    min-width: 220px;

    @media (--mobileOnly) {
      margin: 0 auto;
    }

    & .SvgIcon {
      transform: rotateZ(270deg);
      transition: transform 0.3s;
    }
  }

  & .TestResult__tryAgainButton {
    min-width: 220px;

    @media (--mobileOnly) {
      margin: 0 auto;
    }
  }

  & .TestResult__buttonText {
    min-width: 80px;
  }

  & .TestResult__button--reversed .SvgIcon {
    transform: rotate(90deg);
  }

  &.TestResult--passed {
    & .TestResult__title {
      color: var(--colorActionSuccess);
    }
  }
}
