.Test {
  position: relative;

  & .Test__moduleName {
    @mixin p30;
    font-weight: var(--boldFontWeight);
    margin-bottom: 8px;

    @media (--tablet) {
      margin-bottom: 16px;
    }
  }

  & .Test__title {
    @mixin h50;
    font-weight: var(--boldFontWeight);
    margin-bottom: 16px;

    @media (--tablet) {
      @mixin h10;
      margin-bottom: 32px;
    }
  }

  & .Test__description {
    @mixin p30;
    color: var(--secondaryBlack);
    margin-bottom: 48px;

    @media (--tablet) {
      @mixin p20;
      margin-bottom: 80px;
    }
  }

  & .Test__form {
    & .Test__taskList {
      @media (--tablet) {
        margin-bottom: 48px;
      }
    }

    & .Test__submitForm {
      padding-top: 24px;
      border-top: 1px solid var(--secondaryGrey);
    }

    & .Test__submitButton {
      margin-left: auto;

      @media (--mobileOnly) {
        width: 100%;
      }
    }
  }

  & .Test__form--hidden {
    display: none;
    overflow: hidden;
  }

  &.Test--submitted {
    & .Test__moduleName {
      @media (--mobileOnly) {
        display: none;
      }
    }

    & .Test__title {
      @media (--mobileOnly) {
        @mixin visuallyHidden;
      }
    }
  }
}
