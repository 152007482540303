@define-mixin rightsIndicator $size: 16px, $padding: 2px {
  & .Avatar__rightsIndicator {
    width: $size;
    height: $size;
    padding: $padding;
  }
}

@define-mixin smallAvatar {
  @mixin p50;
  @mixin rightsIndicator;
  width: 32px;
}

@define-mixin mediumAvatar {
  @mixin p30;
  @mixin rightsIndicator;
  width: 40px;
}

@define-mixin largeAvatar {
  @mixin h50;
  @mixin rightsIndicator;
  width: 48px;
}

@define-mixin xLargeAvatar {
  @mixin h40;
  @mixin rightsIndicator 24px, 4px;
  width: 80px;
}

@define-mixin xxLargeAvatar {
  @mixin h30;
  @mixin rightsIndicator 32px, 8px;
  width: 112px;
}

@define-mixin xxxLargeAvatar {
  @mixin h20;
  @mixin rightsIndicator 48px, 12px;
  width: 200px;
}
