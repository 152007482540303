.ProgressRing {
  @mixin aspectRatio 1/1;
  width: 100%;
  position: relative;
  border-radius: 50%;

  & .ProgressRing__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: var(--circleProgressBarRadius);
  }

  & .ProgressRing__indicator {
    transform: rotateZ(-90deg);
    transform-origin: center;
    stroke: var(--colorActionSuccess);
  }

  & .ProgressRing__trail {
    stroke: var(--lightGrey);
  }

  &.ProgressRing--warning {
    & .ProgressRing__indicator {
      stroke: var(--colorActionWarning);
    }
  }

  &.ProgressRing--error {
    & .ProgressRing__indicator {
      stroke: var(--colorActionError);
    }
  }
}
