/* stylelint-disable declaration-property-value-disallowed-list */

@define-mixin h10 {
  --fontSize: 48px;
  --lineHeight: 64px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

@define-mixin h20 {
  --fontSize: 40px;
  --lineHeight: 56px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

@define-mixin h30 {
  --fontSize: 32px;
  --lineHeight: 48px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

@define-mixin h40 {
  --fontSize: 24px;
  --lineHeight: 32px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

@define-mixin h50 {
  --fontSize: 20px;
  --lineHeight: 28px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

@define-mixin p10 {
  --fontSize: 20px;
  --lineHeight: 28px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

@define-mixin p20 {
  --fontSize: 18px;
  --lineHeight: 26px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

@define-mixin p30 {
  --fontSize: 16px;
  --lineHeight: 24px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

@define-mixin p40 {
  --fontSize: 14px;
  --lineHeight: 20px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

@define-mixin p50 {
  --fontSize: 12px;
  --lineHeight: 16px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

/* stylelint-enable declaration-property-value-disallowed-list */
