.Modal {
  @mixin fixedFullSize;
  z-index: 2000;

  & .Modal__closeBackgroundButton {
    @mixin fixedFullSize;
    background: color(var(--darkBackgroundColor) a(80%));
  }

  & .Modal__outer {
    @media (--mobileOnly) {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    @media (--tablet) {
      @mixin wrapper;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  & .Modal__wrapper {
    position: relative;
    z-index: 1;

    @media (--desktop) {
      @mixin lost-column 8/12;
      min-width: 840px;
    }
  }

  & .Modal__panel {
    display: flex;
    flex-direction: column;
    background-color: var(--lightBackgroundColor);
    color: var(--textColor);
    max-height: calc(100vh - 128px);
    overflow-y: auto;
    padding: 32px 32px 48px;
    border-radius: 16px 16px 0 0;

    @media (--tablet) {
      padding: 64px 64px 80px;
      border-radius: 24px;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track {
      border-radius: inherit;
      margin: 32px 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--secondaryLighten10);
      border: 4px solid var(--white);
      border-radius: 12px;
    }
  }

  & .Modal__inner {
    width: 100%;
    height: 100%;
  }

  & .Modal__closeButonWrapper {
    display: none;

    @media (--tablet) {
      display: block;
    }
  }

  & .Modal__closeButton {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    z-index: 101;
  }
}
