.TestTask {
  margin-bottom: 48px;

  @media (--tablet) {
    margin-bottom: 80px;
  }

  & .TestTask__heading {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &::after {
      content: "";
      background-color: var(--secondaryGrey);
      height: 1px;
      flex: 1;
    }
  }

  & .TestTask__number {
    @mixin p20;
    color: var(--white);
    background-color: var(--secondaryBase);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin: 0 16px 0 0;

    @media (--tablet) {
      @mixin h50;
      width: 48px;
      height: 48px;
      margin-right: 24px;
    }
  }
}
