.SliderThumbnail {
  @mixin actionOverlay;
  height: 100%;
  position: relative;
  padding-bottom: 8px;

  &.SliderThumbnail--active {
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 8px;
      left: 0;
      bottom: 0;
      background-color: var(--backgroundColor);
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }

  & .SliderThumbnail__imageContainer {
    @mixin aspectRatio 9/16;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 8px;

    & .SliderThumbnail__image {
      object-fit: cover;
    }
  }

  & .SliderThumbnail__infoContainer {
    margin-bottom: 32px;

    & .SliderThumbnail__title {
      @mixin p50;
    }
  }

  & .SliderThumbnail__actionOverlay {
    @mixin offsetFocus -4px;
  }
}
