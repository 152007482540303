.CookiePage {
  max-width: 1440px;
  overflow: auto;
  position: relative;
  padding: 0 64px;
  margin: 0 auto 36px;

  @media (--mobileOnly) {
    padding: 0 12px;
  }

  & .CookiePage__your-settings-header-container {
    display: flex;
    justify-content: center;
  }

  & h1 {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  & p {
    margin: 16px 16px 16px 0;
  }

  & input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  & label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 30px;
    background: grey;
    display: block;
    position: relative;
    border-radius: 100px;

    &::after {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      width: 20px;
      height: 20px;
      background: #ffffff;
      transition: 0.3s;
      border-radius: 90px;
    }
  }

  & input:checked + label {
    background: #bada55;

    &::after {
      left: calc(100% - 5px);
      transform: translateX(-100%);
    }
  }
}

& .CookiePage__title-and-toggle-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
}

& .YourCookieSettings__submit-button-wrapper {
  display: flex;
  justify-content: center;
}

& .YourCookieSettings__saved-feedback-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

& .YourCookieSettings__empty-div {
  min-height: 24px;
  min-width: 200px;
  margin-top: 16px;
}

.YourCookieSettings {
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  padding: 12px;
  border-radius: 16px;
  margin-bottom: 36px;

  @media (--mobileOnly) {
    font-size: 0.8em;

    & button {
      font-size: 0.6em;
    }
  }

  & button {
    & p {
      margin: 0;
    }
  }
}
