.CollapseGroupWithFootnotes {
  & .CollapseGroupWithFootnotes__footnotesList {
    @mixin clearList;
    margin-top: 48px;

    @media (--tablet) {
      margin-top: 40px;
    }
  }

  & .CollapseGroupWithFootnotes__footnotesListItem {
    @mixin p30;

    @media (--mobileOnly) {
      margin-top: 24px;
    }
  }
}
