.TermsAndConditions {
  max-width: 1440px;
  padding: 0 64px;
  margin: 0 auto 36px;

  @media (--mobileOnly) {
    padding: 0 32px;
  }

  & h1 {
    margin-top: 64px;
  }
}
