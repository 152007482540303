.HorizontalThumbnail {
  @mixin actionOverlay;
  display: inline-flex;
  align-items: flex-start;
  max-width: 100%;

  & .HorizontalThumbnail__imageContainer {
    @mixin aspectRatio 1/1;
    width: 85px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 8px;
    margin-right: 16px;

    @media (--tablet) {
      @mixin aspectRatio 9/16;
      width: 160px;
    }

    & .HorizontalThumbnail__image {
      object-fit: cover;
    }
  }

  & .HorizontalThumbnail__info {
    flex: 1;
  }

  & .HorizontalThumbnail__title {
    @mixin p40;
    @mixin breakWithHyphen;
    @mixin lineClamp 3;
    font-weight: var(--boldFontWeight);

    @media (--tablet) {
      @mixin h50;
    }
  }

  & .HorizontalThumbnail__actionOverlay {
    @mixin offsetFocus;
  }
}
