@define-mixin darkPageBase {
  background-color: var(--darkBackgroundColor);
  color: var(--lightTextColor);
  flex: 1;
  padding-bottom: 80px;

  @media (--tablet) {
    padding-bottom: 96px;
  }
}
