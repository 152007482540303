.MenuContent {
  position: relative;

  & .MenuContent__inner {
    @mixin listWithCustomScroll 16px;
    max-height: 128px;
    margin-top: 24px;

    &.MenuContent__inner--isLoading {
      margin-top: 0;
    }
  }

  & .MenuContent__items {
    @mixin clearList;
    width: 100%;
  }

  & .MenuContent__item {
    pointer-events: none;
  }

  & .MenuContent__noElements {
    @mixin h50;
    color: var(--secondaryLighten30);
    font-weight: var(--boldFontWeight);
    text-align: center;
  }

  & .MenuContent__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
  }

  & .MenuContent__loadMoreButtonWrapper {
    padding: 8px 0;
  }

  & .MenuContent__loadMoreButton.MenuContent__loadMoreButton.MenuContent__loadMoreButton {
    @mixin secondaryOutlineButton;
    @mixin smallButton;
    margin: 0 auto;
  }

  & .MenuContent__loader {
    background-color: var(--white);
  }
}
