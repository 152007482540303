.GroupData {
  @mixin adminListItemBaseData;

  & .GroupData__info {
    @mixin adminListItemBaseDataInfo;
  }

  & .GroupData__groupName {
    @mixin adminListItemBaseDataName;
  }
}
