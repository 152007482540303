/* stylelint-disable no-descending-specificity */

.MemberList {
  & .MemberList__header {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;

    & label {
      flex: 1;
      flex-basis: calc(100% - 46px - 16px);
      min-width: 0;

      @media (--smallPhone) {
        flex-basis: calc(100% - 46px - 16px);
        font-size: 0.5em;
        width: 20px;
      }
    }

    & .MemberList__button.MemberList__button.MemberList__button {
      @mixin primarySolidButton;
      min-width: 290px;
      margin-left: auto;

      @media (--mobileOnly) {
        @mixin primaryCircleButton;
        min-width: auto;
      }
    }

    & .FilterWindow {
      padding: 0;
      border: none;
      margin: 0;

      & .Checkbox__labelContent {
        margin-top: 4px;
      }

      & legend {
        opacity: 0;
        padding: 0;
        margin: 0;
      }

      & ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0;
        margin: 0;
      }
    }

    & .MemberList__buttonText {
      @media (--mobileOnly) {
        @mixin visuallyHidden;
      }
    }

    & .Checkbox__labelContent {
      position: relative;
      margin-right: 16px;
    }

    & .MemberList__filterTags {
      display: none;

      @media (--desktop) {
        display: flex;
        gap: 8px;
      }
    }

    & .MemberList__filterTag {
      cursor: pointer;
      background-color: var(--white) !important;
      padding: 6px 26px;
      border-radius: 30px;

      @media (--tablet) {
        display: inline-block;
      }
    }
  }

  & .MemberList__filtersWrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 40px 0 0 16px;

    @media (--tablet) {
      margin: 64px 0 0 32px;
    }

    & .MemberList__myNetworkCheckboxWrapper {
      margin: 0;

      & .MemberList__myNetworkCheckbox + label {
        @mixin shiftPseudoElementsVertically 0;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  & .MemberList__list {
    margin-top: 16px;

    @media (--tablet) {
      margin-top: 32px;
    }
  }

  & .MemberList__listSizeMarkup {
    margin-left: 16px;

    @media (--tablet) {
      margin-left: 32px;
    }
  }

  & .MemberList__loadMoreButton {
    margin: 32px auto 0;
  }

  & .MemberList__input {
    flex: 1;

    @media (--tablet) {
      max-width: 400px;
    }
  }
}

.MyEmployeesCheckboxContainer {
  align-self: flex-start;
  margin-top: 10px;
}

/* stylelint-enable no-descending-specificity */
