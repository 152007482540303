.Footer {
  display: flex;
  min-height: var(--footerMinHeight);
  background-color: var(--darkBackgroundColor);
  color: var(--lightTextColor);

  & .Footer__inner {
    @mixin wrapper;
    display: flex;
    flex-direction: column;

    @media (--tablet) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  & .Footer__logo {
    width: 144px;
    margin: 40px 0 32px;

    & svg {
      fill: var(--lightTextColor);
    }
  }

  & .Footer__linkList {
    @mixin clearList;

    @media (--mobileOnly) {
      margin-bottom: 48px;
    }

    @media (--tablet) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    & .Footer__link {
      margin: 0 0 16px;

      @media (--tablet) {
        margin: 0 0 0 24px;
      }
    }
  }
}
